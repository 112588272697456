import { Router } from '@angular/router';
import { UtilsService } from './../../../services/utils.service';
import { ChatService } from './../../../services/chat.service';
// import { switchMap, take } from 'rxjs/operators';
import { AuthService } from './../../../services/auth.service';
import { PostProvider } from 'src/app/providers/post.provider';
import { UserProvider } from './../../../providers/user.provider';
import { ChatProvider } from './../../../providers/chat.provider';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatModel } from 'src/app/schemes/models/chat.model';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit, OnDestroy {

  chats: { chat: ChatModel, img?: string, title?: string, username?: string, price?: number, productOwner?: string, customId?: string, deletedPost?: boolean }[];
  chatsSubscription: Subscription;
  chatsSubscriptions: Subscription[] = [];
  currentChats: Observable<ChatModel>[];
  productsImgs: { [key: string]: string } = {};
  pauseSubscription: Subscription;
  resumeSubscription: Subscription;
  storedChatsView: boolean = false;
  chatSelected: boolean = false;
  data: { chat: any; price: number; product: string; productImg: string; productOwner: string; customId: string; deleted: true; };
  showSidebarMobile = false;

  constructor(
    private chatProvider: ChatProvider,
    private userProvider: UserProvider,
    private postProvider: PostProvider,
    private authService: AuthService,
    private chatService: ChatService,
    private utilsService: UtilsService,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    const data = this.utilsService.getDataFromNavigation();
    this.utilsService.setDataForNavigation('');
    if(data && data.fromPost){
      this.setChatData(data);
    }
    this.storedChatsView = false;
    // this.pauseSubscription = this.platform.pause.subscribe(_ => {
    //   this.userProvider.updateUserField(this.authService.getCurrentUserUid(), { currentChatTab: false });
    // })
    // this.resumeSubscription = this.platform.resume.subscribe(_ => {
    //   this.userProvider.updateUserField(this.authService.getCurrentUserUid(), { currentChatId: true });
    // })
    // this.userProvider.updateUserField(this.authService.getCurrentUserUid(), { currentChatTab: true });
    this.chatService.getUserChats().subscribe(res => {
      console.log({res})
      this.chats = res;
      this.showSidebarMobile = !this.chatSelected && this.chats.length > 0 && window.innerWidth <= 770 ? true : false;
    });
  }
  getOpenChatsOrderedByUpdatedAt() {
    return this.chats.filter(chat => !chat.chat.closed).sort((a, b) => b.chat.updatedAt - a.chat.updatedAt);
  }

  getClosedChatsOrderedByUpdatedAt() {
    return this.chats.filter(chat => chat.chat.closed).sort((a, b) => b.chat.updatedAt - a.chat.updatedAt);
  }

  getFilteredChatsByStoredOrNot(chats: {
    chat: ChatModel;
    img?: string;
    title?: string;
    username?: string;
    price?: number;
    productOwner?: string;
    customId?: string,
    deletedPost?: boolean;
  }[], stored: boolean): {
    chat: ChatModel;
    img?: string;
    title?: string;
    username?: string;
    price?: number;
    productOwner?: string;
    customId?: string;
    deletedPost?:boolean
  }[] {
    return chats.filter(chat =>
      chat.chat.members.find(member =>
        member.userId == this.authService.getCurrentUserUid() && member.storedChat == stored))
  }

  ngOnDestroy(): void {
    // this.userProvider.updateUserField(this.authService.getCurrentUserUid(), { currentChatTab: false });
  }

  async setChatData(data:any): Promise<void> {
    // this.utilsService.setDataForNavigation('');
    console.log("va a navegar");
    // this.chatSelected = false;
    this.chatSelected = false;
    setTimeout(() => {
      this.chatSelected = true;
      this.data = JSON.parse(JSON.stringify(data));
      console.log("ahora");
    }, 0);
    // this.utilsService.setDataForNavigation();
    // this.router.navigateByUrl(`/client/chats/${chat.id}`);
  }


  getUnreadMessagesOfChat(chat: ChatModel) {
    return chat.totalMessages - chat.readMessages[this.authService.getCurrentUserUid()];
  }

  async storeChat(chatId: string, members: { userId: string, storedChat: boolean }[], store: boolean) {
    members.find(member => member.userId == this.authService.getCurrentUserUid()).storedChat = !this.storedChatsView;
    await this.chatProvider.updateChat(chatId, { members: members });
  }

  trackByItems(index: number, chat: ChatModel): string { return chat.id; }

  isToday(chatTimestamp: number): boolean{
    const chatDate = new Date(chatTimestamp);
    const nowDate = new Date();
    return (chatDate.getDate() == nowDate.getDate() && chatDate.getMonth() == nowDate.getMonth() &&
    chatDate.getFullYear() == nowDate.getFullYear())
  }

  changeSidebarValue(event) {
    this.showSidebarMobile = event;
  }
}

