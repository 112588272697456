import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserProvider } from 'src/app/providers/user.provider';
import { AngularFireDatabase } from '@angular/fire/database';
import { UserModel } from 'src/app/schemes/models/user.model';
import { take, startWith, map } from 'rxjs/operators';
import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, of, Observable } from 'rxjs';
import { Provinces } from 'src/app/schemes/models/provinces.model';
import { Municipalities } from 'src/app/schemes/models/municipalities.model';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit, OnDestroy {
  user: UserModel;
  password: string;
  registerForm: FormGroup;
  loginForm: FormGroup;
  loginFailed: boolean;
  step: string = 'login';
  showRegisterErros: boolean;

  provinces: {id: string, nm: string}[];
  municipalities: {id: string, nm: string}[];
  municipalitiesToShow: {id: string, nm: string}[] = [];
  provincesOptions: {id:string, nm: string}[] = [];
  municipalitiesOptions: {id:string, nm: string}[] = [];
  municipalitiesSubscription: Subscription;
  provincesSubscription: Subscription;
  municipalitieError: boolean = false;
  provinceError: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<LoginModalComponent>,
    private db: AngularFireDatabase,
    private authService: AuthService,
    private userProvider: UserProvider,
    private formBuilder: FormBuilder,
    private router: Router,
    private utilsService: UtilsService,
    private angularFireDatabase: AngularFireDatabase,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    this.loginForm = this.createForm();
    this.registerForm = this.createRegisterForm();
    this.loginFailed = false;
    this.dialogRef.backdropClick().pipe(take(1)).toPromise().then(() => this.dialogRef.close('hola que ase'));
    this.provinces = new Provinces().provinces;
    this.municipalities = new Municipalities().municipalities;
    this.checkProvincesInput();
    if (!this.registerForm.get('province').value || this.registerForm.get('province').value.id == '00') {
      console.log("disable");
      this.registerForm.controls.municipalitie.disable();
    }
    this.provincesSubscription = this.registerForm.controls.province.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, 'provinces', 'provincesOptions'))
      ).subscribe();
  }

  ngOnDestroy(){
    if(this.municipalitiesSubscription)this.municipalitiesSubscription.unsubscribe();
    if(this.provincesSubscription)this.provincesSubscription.unsubscribe();
  }

  closeDialog(): void {
    this.dialogRef.close('hola que ase');
  }

  ngAfterViewChecked() {
    console.log("after");

    this.changeDetectorRef.detectChanges();
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        password: [null, [Validators.required]],
        remember: [''],
    });
  }

  async onLogin(): Promise<void> {
    this.utilsService.presentLoading();
    try {
        const userCredential = await this.authService.login(this.loginForm.value.email, this.loginForm.value.password);
        if (userCredential) {
          (this.loginForm.get('remember').value) ? localStorage.setItem('email', this.loginForm.get('email').value) : null;
          this.router.navigateByUrl(this.data.mode);
          if (!this.authService.getCurrentFbUser().emailVerified) {
            this.authService.sendCustomVerificationEmail(this.authService.getCurrentFbUser().email);
            this.utilsService.showPrimeToast('error', '', 'Porfavor verifique su correo electronico');
            this.router.navigateByUrl(this.data.mode);
          }
          this.utilsService.dissmissLoading();
          this.closeDialog();
        }
    } catch (error) {
        this.loginFailed = true;
        console.log({error});
        (error.code === 'auth/user-not-found') ? this.utilsService.showPrimeToast('error', '', 'El usuario no existe, inténtelo con otro usuario') : null;
        (error.code === 'auth/wrong-password') ? this.utilsService.showPrimeToast('error', '', 'La contraseña es incorrecta, inténtelo de nuevo') : null;
        (error.code === 'auth/too-many-requests') ? this.utilsService.showPrimeToast('error', '', 'Se ha intentado loguear demasiadas veces. Por favor, vuelva a intentarlo después de  1 minuto') : null;
        this.utilsService.dissmissLoading();
      }
  }

  async logOut(): Promise<void> {
    await this.authService.logout();
    this.router.navigate(['/']);
  }

  createRegisterForm(): FormGroup {
    return this.formBuilder.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        province: ['', [Validators.required]],
        municipalitie: ['', [Validators.required]],
        conditions: ['', [Validators.required]],
        passwords: this.formBuilder.group({
          password: ['', [Validators.minLength(6), Validators.required]],
          password_confirmation: ['']
      }, { validator: this.passwordConfirming() }),
    });
  }

  passwordConfirming(): ValidatorFn {
      return (passwordGrp: AbstractControl): { invalidMatch: boolean } | null => {
          return passwordGrp.value.password === passwordGrp.value.password_confirmation
              ? null
              : { invalidMatch: true };
      };
  }

  async onRegisterUser(): Promise<void> {
    this.utilsService.presentLoading();
    if(!this.registerForm.valid || this.checkIfMunicipalitieIsInCorrect() || this.provinceError){
      console.log({registerForm: this.registerForm});
      this.showRegisterErros = true;
      this.utilsService.showPrimeToast('error', '', 'Los datos introducidos, no son válidos, vuelva a intentarlo');
      this.utilsService.dissmissLoading();
      return;
    }

    const userCredential: firebase.auth.UserCredential | void = await this.authService.createUser(this.registerForm.value.email, this.registerForm.value.passwords.password)
    .catch(err => {
      if (err.code === 'auth/email-already-in-use') { this.utilsService.showPrimeToast('error', 'Correo en uso', 'El correo indicado, ya está en uso'); }
      // console.log("wat?");
    });
    console.log(userCredential);

    if (userCredential) {
        const user: UserModel = new UserModel(this.angularFireDatabase);
        user.name = this.registerForm.get('name').value;
        user.province = this.registerForm.get('province').value
        user.municipalitie = this.registerForm.get('municipalitie').value
        user.id = userCredential.user.uid;
        user.createdAt = Date.now();
        user.email = userCredential.user.email;
        user.customIdName = user.name.split(' ').map(word => word.split('')[0]).join('').toUpperCase();
        await this.userProvider.createUser(user);
        const response = await this.authService.sendCustomVerificationEmail(user.email).catch(err =>{
            console.log(err);
            this.utilsService.showPrimeToast('error', 'Ha ocurrido un error, vuelva a intentarlo', '');
            return false;
        });
        if(response != false){
            this.utilsService.showPrimeToast('success', 'Usuario registrado', 'Usuario registrado correctamente, se le ha enviado un correo de confirmación');
        }
    }
    this.utilsService.dissmissLoading();
    return;
  }

  async onRememberPassword() {
    this.utilsService.presentLoading();
    const emailReponse = await this.authService.sendResetPasswordEmail(this.loginForm.get('email').value)
    .catch(e => {
        this.utilsService.showPrimeToast('error', '', 'Ha ocurrido un error, inténtelo de nuevo');
        this.utilsService.dissmissLoading();
    });
    (emailReponse) ? this.utilsService.showPrimeToast('success', '', `¡se ha enviado un correo a ${this.loginForm.get('email').value} con un link para resetear tu contraseña!`) : null;
    this.utilsService.dissmissLoading();
}

private _filter(value: string | { id: string, nm: string }, arrNameToSearch: string, arrResult): Observable<void> {
  console.log(value);
  console.log(arrNameToSearch);

  if (!value || typeof value == "object" && value.id == "00") {
    this[arrResult] = this[arrNameToSearch];
    console.log(this.provincesOptions);
    return of();
  }
  if (typeof value == "string") {
    const filterValue = value.toLowerCase();
    this[arrResult] = this[arrNameToSearch].filter(x => x.nm.toLowerCase().includes(filterValue) || x.id == "00");
    return of();
  } else {
    this[arrResult] = this[arrNameToSearch].filter(x => x.nm.toLowerCase().includes(value.nm.toLowerCase()) || x.id == "00");
    return of();
  }

}

  checkProvincesInput(){
    this.municipalitiesToShow = this.municipalities.filter(municipalitie => municipalitie.id.indexOf(this.registerForm.get('province').value.id) == 0)
    if(this.municipalitiesSubscription)this.municipalitiesSubscription.unsubscribe();
    this.municipalitiesSubscription = this.registerForm.controls.municipalitie.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, 'municipalitiesToShow','municipalitiesOptions'))
    ).subscribe();
  }

  checkMunicipalitiesInput(){
    if(!this.registerForm.get('municipalitie').value)return;
    if(!this.municipalities.find(r => r.nm == this.registerForm.get('municipalitie').value.nm)){
      this.registerForm.get('municipalitie').setValue(null);
    }
  }
  displayFn(province): string {
    return province && province.nm ? province.nm : '';
  }

  filterProvinces(){
    console.log("y aqui?");

    if(!this.registerForm.get('province').value)return this.provinces;
    if(!this.registerForm.get('province').value.nm)return this.provinces.filter(province => province.nm.toLowerCase().includes(this.registerForm.get('province').value));
    console.log("jum");

    return this.provinces.filter(province => province.nm.toLowerCase().includes(this.registerForm.get('province').value.nm));
  }

  filterMunicipalities(){
    if(!this.registerForm.get('municipalitie').value)return this.municipalities;
    if(!this.registerForm.get('municipalitie').value.nm)return this.municipalities.filter(municipalitie => municipalitie.nm.toLowerCase().includes(this.registerForm.get('municipalitie').value.toLowerCase()));
    return this.municipalities.filter(municipalitie => municipalitie.nm.toLowerCase().includes(this.registerForm.get('municipalitie').value.nm.toLowerCase()));
  }

  checkIfProvinceIsInCorrect(){
    console.log("holis");
    if(this.registerForm.disabled)return false;
  let provinceFound;
  if(!this.registerForm.get('province').value){
    this.registerForm.controls.municipalitie.disable();
    return true;
  }else{
    if(!this.registerForm.get('province').value.nm){
      provinceFound = this.provinces.find(province => province.nm.toLowerCase() == this.registerForm.get('province').value.toLowerCase());
      if(!provinceFound){
        this.registerForm.controls.municipalitie.disable();
        this.provinceError = true;
        return true;
      }else{
        this.registerForm.get('province').setValue(provinceFound);
        this.checkProvincesInput();
        this.registerForm.controls.municipalitie.enable();
        this.provinceError = false;
        return false;
      }
    }
    this.registerForm.controls.municipalitie.enable();
    this.provinceError = false;
    return false;
  }
}

  checkIfMunicipalitieIsInCorrect(){
    let municipalitieFound;
    if(!this.registerForm.get('municipalitie').value){
      this.municipalitieError = true;
      return true;
    }else{
      if(!this.registerForm.get('municipalitie').value.nm){
        municipalitieFound = this.municipalities.find(municipalitie => municipalitie.nm.toLowerCase() == this.registerForm.get('municipalitie').value.toLowerCase());
        if(!municipalitieFound){
          this.municipalitieError = true;
          return true;
        }else{
          this.registerForm.get('municipalitie').setValue(municipalitieFound);
          this.municipalitieError = false;
          return false;
        }
      }
      this.municipalitieError = false;
      return false;
    }
  }

  changeStep(step: string) {
    this.step = step;
    if (step === 'register') {
      this.provincesSubscription = this.registerForm.controls.province.valueChanges
        .pipe(
        startWith(''),
        map(value => this._filter(value, 'provinces', 'provincesOptions'))
      ).subscribe()
    }
  }

  isInvalid(entry: string): boolean {
      return !this.registerForm.get(entry).valid && this.showRegisterErros;
  }


  passwordsDontMatch(): boolean {

      return this.registerForm.get('passwords').errors !== null
          ? this.registerForm.get('passwords').errors.invalidMatch && this.showRegisterErros
          : false;
  }
}
