import { ChatService } from 'src/app/services/chat.service';
import { SearchService } from './../../services/search.service';
import { MatDialog } from '@angular/material';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserProvider } from 'src/app/providers/user.provider';
import { Subscription } from 'rxjs';
import { UserModel } from 'src/app/schemes/models/user.model';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    user: UserModel;
    userSubscription: Subscription;
    searchInput: string;
    searchInputSubscription: Subscription;
    routesSubscription: Subscription;
    showSidenav = false;
    active = '';
    selected = '';
    selectedTab = false;

    constructor(
        private userProvider: UserProvider,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private utils: UtilsService,
        private router: Router,
        private loginModal: MatDialog,
        private searchService: SearchService,
        private chatService: ChatService,
    ) { }

    ngOnInit() {
        this.userSubscription = this.authService.getCurrentUser().subscribe(user => {
            this.user = user;
        });
        this.searchInputSubscription = this.searchService.getSearchBH().subscribe(searchInput => {
          this.searchInput = searchInput;
        });
        const routesSelectedTab = ['products', 'product', 'profile'];
        const routesSelected = ['products', 'client/profile', 'client/posts', 'client/chats', 'client/favourites'];
        this.routesSubscription = this.router.events.subscribe((event: any) => {
          if (event && event.url && event.urlAfterRedirects) {
            this.selectedTab = routesSelectedTab.some(route => event.urlAfterRedirects.includes(route));
            routesSelected.map(route => this.selected = event.urlAfterRedirects.includes(route) ? route : this.selected);
          }
        });

    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    openChat(): void {
        (this.user) ? this.router.navigateByUrl('/chats') : this.openLoginModal('/chats');
    }

    openProducts(): void {
        (this.user) ? this.router.navigateByUrl('/client/posts/create') : this.openLoginModal('/posts/create');
    }

    openLoginModal(mode: string = null): void {
        const dialogRef = this.loginModal.open(LoginModalComponent, {
          width: '662px',
          height: '620px',
          data: {
            mode
          },
          panelClass: 'no-padding-modal',
        });

        dialogRef.afterClosed().subscribe(async (status) => {
            if (!status) { return; }
              // this.proposal.status = ProposalStatus.accepted;
              // this.proposal.payments[0].status = PaymentStatusEnum.pending;
              // await this.proposalProvider.updateProposal(this.proposal);
        });
    }

    enterKey(){
      this.searchService.setSearchBHValue(this.searchInput);
      console.log(this.router.url);

      if(this.router.url != "/products" && this.router.url != "/client/posts"){
        console.log("navegando");
        this.router.navigateByUrl('/products');
      }
    }

    getUnreadMessagesOfChats() {
      return this.chatService.getChatsNotifications();
    }

    logOut(): void {
      this.authService.logout();
      this.showSidenav = false;
      this.router.navigate(['/products']);
  }
}
