import { AngularFireDatabase } from '@angular/fire/database';
import { StockUnits } from '../enums/stock-units.enum';

export class PostModel {
    id: string;
    title: string;
    userId: string;
    email: string;
    category: string;
    product: Product;
    price: number;
    phone?: string;
    address?: string;
    photoUrls?: string[];
    createdAt: number;
    chats?: string[];
    description: string;
    province: {id:string, nm: string};
    municipalitie: {id: string, nm: string};
    customId?: string;

    constructor(utils: AngularFireDatabase) {
        this.id = utils.createPushId();
        this.title = '';
        this.userId = '';
        this.email = '';
        this.category = '';
        this.product = {
            name: '',
            brand: '',
            model: '',
            price: 0,
            stockUnit: ''
        };
       
    }
}

export interface Product {
    name?: string;
    brand: string;
    model: string;
    price: number;
    stock?: number;
    stockUnit: StockUnits | string;
}


export interface Category {
    name: string;
    iconUrl: string;
    weight: number;
}
