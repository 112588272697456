import { TabsService } from './../../services/tabs.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserModel } from 'src/app/schemes/models/user.model';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    user: UserModel;
    userSubscription: Subscription;
    routesSubscription: Subscription;
    constructor(
        private authService: AuthService,
        private router: Router,
        private tabsService: TabsService
    ) { }

    ngOnInit() {
        this.userSubscription = this.authService.getCurrentUser().subscribe(user => {
            this.user = user;
            // if (user) { this.router.navigateByUrl(''); }
        });
        if(this.router.url == '/') {
        this.router.navigateByUrl('/products');
        }
        this.routesSubscription = this.router.events.subscribe((event: any) => (event && event.url) ? this.setSelectedTab(event) : null);
    }

    setSelectedTab(event) {
        const routeNames = ['profile', 'posts', 'chats', 'favourites'];
        let selectedTab = '';
        if (event.urlAfterRedirects) {
            routeNames.map(route => selectedTab = event.urlAfterRedirects.includes(route) ? route : selectedTab);
        } else {
            routeNames.map(route => selectedTab = event.url.includes(route) ? route : selectedTab);
        }
        this.tabsService.setTabActive(selectedTab);
    }

    async logOut(): Promise<void> {
        await this.authService.logout();
        this.router.navigate(['/posts']);
    }

    backgroundStyle() {
        return {
          'background-image': (false) ? `url()` : 'none',
        };
    }

    ngOnDestroy(): void {
        this.routesSubscription.unsubscribe();
    }

}
