export const environment = {
    production: true,
    firebase: {
        apiKey: 'AIzaSyCHWJM-VjnUn6W4DLk811mEG9eq6wbvuFE',
        authDomain: 'wallectric-prod.firebaseapp.com',
        databaseURL: 'https://wallectric-prod.firebaseio.com',
        projectId: 'wallectric-prod',
        storageBucket: 'wallectric-prod.appspot.com',
        messagingSenderId: '277870366204',
        appId: '1:277870366204:web:a72989340623c7bae9f679',
        measurementId: 'G-N40KTR7C0H'
    },
    cloudFunctionsUrl: 'https://us-central1-wallectric-prod.cloudfunctions.net/',
    url: 'https://wallectric-prod.firebaseapp.com'
};
