import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserModel } from './../schemes/models/user.model';
import { UserProvider } from './../providers/user.provider';
import { switchMap } from 'rxjs/operators';
import { of, ReplaySubject, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
// import { FacebookLoginResponse, Facebook } from '@ionic-native/facebook/ngx';
// import { GooglePlus } from '@ionic-native/google-plus/ngx';
import * as firebase from 'firebase/app';
import { UtilsService } from './utils.service';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private currentUser: ReplaySubject<UserModel> = new ReplaySubject(1);
    constructor(
        private afAuth: AngularFireAuth,
        private utils: UtilsService,
        // private facebook: Facebook,
        // private google: GooglePlus,
        private userProvider: UserProvider,
        private db: AngularFireDatabase,
        private http: HttpClient
    ) {  this.setCurrentUser(); }

    login(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    }

    logout(): void {
        this.afAuth.auth.signOut();
    }

    createUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    }

    getCurrentUserUid(){
        return this.afAuth.auth.currentUser.uid;
    }


    getCurrentUser(){
        console.log(this.currentUser);

        return this.currentUser;
    }


    getCurrentFbUser() {
        return this.afAuth.auth.currentUser;
    }

    getCurrentFirebaseUserEmail(): string {
        return this.afAuth.auth.currentUser.email;
    }

    updateCurrentUserEmail(newEmail: string): Promise<void> {
        return this.afAuth.auth.currentUser.updateEmail(newEmail);
    }


    updateCurrentUserPassword(newPassword: string): Promise<void> {
        return this.afAuth.auth.currentUser.updatePassword(newPassword);
    }

    // async loginWithFacebook(): Promise<firebase.auth.UserCredential> {
    //     const facebookLoginResponse: FacebookLoginResponse = await this.facebook.login(['public_profile', 'email']).catch(this.utils.errorHandling);
    //     if (!facebookLoginResponse) return null;
    //     const facebookCredential = firebase.auth.FacebookAuthProvider.credential(facebookLoginResponse.authResponse.accessToken);
    //     const userCredential: firebase.auth.UserCredential = await firebase.auth().signInWithCredential(facebookCredential).catch(this.utils.errorHandling)
    //     return userCredential;
    // }

    async registerAnonimously(){
        firebase.auth().signInAnonymously().catch(err => {
            console.log(err);
        })
    }

    // async loginWithGoogle(): Promise<firebase.auth.UserCredential> {

    //     console.log("ENTRO");

    //     const googleUser: any = await this.google.login({
    //         'webClientId': '610962606923-cesjuu3vb2belvfll1070purrblfqk03.apps.googleusercontent.com',
    //         'scopes': 'profile email'
    //     }).catch(e => console.error(e))
    //     console.log({ googleUser });

    //     if (!googleUser) return null;

    //     const googleCredential = firebase.auth.GoogleAuthProvider.credential(googleUser.idToken);
    //     console.log({ googleCredential });

    //     const userCredential: firebase.auth.UserCredential = await firebase.auth().signInWithCredential(googleCredential).catch(e => { console.error(e); return null });
    //     return userCredential;
    // }

    async sendResetPasswordEmail(userEmail: string) {
        await this.afAuth.auth.sendPasswordResetEmail(userEmail).catch(e => e);
        return true;
    }

    private setCurrentUser(): void {
        this.afAuth.authState
            .pipe(switchMap(fbUser => fbUser ? this.userProvider.getUserById(fbUser.uid) : of(null)))
            .subscribe((user) => { this.currentUser.next(user);
             });
    }
    sendCustomVerificationEmail(email: string){
        return this.http.post(`${environment.cloudFunctionsUrl}sendVerificationMail`, {email}).toPromise();
    }

    sendCustomResetPasswordEmail(email: string){
        return this.http.post(`${environment.cloudFunctionsUrl}sendVerificationMail`, {email, password: true}).toPromise();
    }
    setToken(token: string) {
        return this.db.object(`users/${this.getCurrentUserUid()}`).update({ token: token });
      }

}
