import { Category } from './post.model';
const assets = '/assets/category-icons/';
export class Categories {
    categories: Category[];
    constructor() {
        this.categories = [
            {name: 'Material eléctrico', iconUrl: `${assets}material_electrico.svg`, weight: 1},
            {name: 'Iluminación', iconUrl: `${assets}iluminacion.svg`, weight: 2},
            {name: 'Herramientas', iconUrl: `${assets}herramientas.svg`, weight: 3},
            {name: 'Telecomunicaciones', iconUrl: `${assets}telecomunicaciones.svg`, weight: 4},
            {name: 'Climatización', iconUrl: `${assets}climatizacion.svg`, weight: 5},
            {name: 'Contra incendio', iconUrl: `${assets}incendio.svg`, weight: 6},
            {name: 'Fontanería', iconUrl: `${assets}fontaneria.svg`, weight: 7},
            {name: 'Construcción', iconUrl: `${assets}construccion.svg`, weight: 8},
        ];
    }
}
