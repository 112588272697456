import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

    tabs$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor() { }

    setTabActive(tab: string): void {
        this.tabs$.next(tab);
    }
    getTabObservable(): BehaviorSubject<string> {
        return this.tabs$;
    }
    
}