import { PostFilterOrders, orderByFilterOrder } from 'src/app/schemes/enums/post-filter-orders.enum';
import { Categories } from './../../schemes/models/categories.model';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { take, startWith, map } from 'rxjs/operators';
import { UserModel } from 'src/app/schemes/models/user.model';
import { MatDialog, MatAutocompleteTrigger, MatMenuTrigger } from '@angular/material';
import { UserProvider } from 'src/app/providers/user.provider';
import { Component, OnInit, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { PostModel } from 'src/app/schemes/models/post.model';
import { PostProvider } from 'src/app/providers/post.provider';
import { Subscription, BehaviorSubject, of, Observable } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { PaymentProvider } from 'src/app/providers/payment.provider';
import { Provinces } from 'src/app/schemes/models/provinces.model';
import { Slider } from 'primeng/slider';
import { SearchService } from 'src/app/services/search.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Municipalities } from 'src/app/schemes/models/municipalities.model';
import { OverlayContainer } from '@angular/cdk/overlay';


@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostsComponent implements OnInit {
  posts: PostModel[] = [];
  editing = false;
  filteredPosts: PostModel[];
  categories: Categories;
  selectedCategory = '';
  searchInput = '';
  position = 0;
  lastScroll = 0;
  interval: any;
  user: UserModel;
  postsSubscription: Subscription;
  favouriteInProcess: { [key: string]: boolean } = {};
  favouritedPosts: { [key: string]: boolean } = {};
  userSubscription: Subscription;
  filters: any = {};
  categoriesPagination: {[key: string]: number} = {};
  premiumPagination: number;
  premiumUserIds: string[] = [];
  provinces: {id: string, nm: string}[];
  // minPrice: number;
  // maxPrice: number;
  originalFilters: {[key: string]: string |number | { id: string, nm: string }};
  range: any[];
  maxPrice: number;
  rangeStep: number;
  postFilterOrderOptions: string[];
  showMenu = false;
  tempMaxPrice: any;
  tempMinPrice: any;
  showPriceFilterMenu: boolean = false;
  search$: Subscription;
  provinceError: boolean = false;
  filterForm: FormGroup;
  provincesSubscription: Subscription;
  municipalities: {id: string, nm: string}[];
  municipalitiesToShow: {id: string, nm: string}[] = [];
  provincesOptions: {id:string, nm: string}[] = [];
  municipalitiesOptions: {id:string, nm: string}[] = [];
  showErrors: boolean = false;
  municipalitiesSubscription: Subscription;
  municipalitieError: boolean = false;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger, static: false }) auto;
  @ViewChild('matMenu2', { read: MatMenuTrigger, static: false })
  menu: MatMenuTrigger;
  tempUbicationProvince: any;
  rangeMaxPrice: number;
  rangeMinPrice: number;

  constructor(
    private router: Router,
    private postProvider: PostProvider,
    private utilsService: UtilsService,
    private authService: AuthService,
    private userProvider: UserProvider,
    private paymentProvider: PaymentProvider,
    private matDialog: MatDialog,
    private searchService: SearchService,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private overlayContainer: OverlayContainer
  ) { }

  focusMenu(){
    this.menu.focus();
  }

  async ngOnInit() {
    this.premiumUserIds = [];
    this.categories = new Categories();
    // this.categories.categories.forEach(category => {
    //   this.categoriesPagination[category.name] = 4;
    // });
    this.search$ = this.searchService.getSearchBH().subscribe(searchValue => {
      this.searchInput = searchValue;
      this.filterPosts();
    })
    const payments = await this.paymentProvider.getActivePayments().pipe(take(1)).toPromise();
    payments.forEach(payment => {
      this.premiumUserIds.push(payment.userId);
    });
    // this.premiumPagination = 4;
    // this.user = await this.userProvider.getUserById(this.authService.getCurrentUserUid()).pipe(take(1)).toPromise();
    this.user = await this.authService.getCurrentUser().pipe(take(1)).toPromise();
    this.postProvider.getPosts().subscribe(posts => {
      this.posts = posts;
      this.filterPosts();
    });
    // if (!this.user.postsLocation || this.user.postsLocation == "Todas las provincias") {
    //   this.postsSubscription = this.postProvider.getPosts().subscribe(posts => {
    //     this.posts = posts;
    //     this.filterPosts();
    //   });
    // } else {
      // this.postsSubscription = this.postProvider.getPostsByLocation(this.user.postsLocation).subscribe(posts => {
      //   this.posts = posts;
      // });
    // }
    this.originalFilters = this.filters;
    this.postFilterOrderOptions = Object.keys(PostFilterOrders);
  }

  ngAfterViewChecked() {
    console.log("after");

    this.changeDetectorRef.detectChanges();
  }

  private _filter(value: string | { id: string, nm: string }, arrNameToSearch: string, arrResult): Observable<void> {
    console.log(value);
    console.log(arrNameToSearch);

    if (!value || typeof value == "object" && value.id == "00") {
      this[arrResult] = this[arrNameToSearch];
      console.log(this.provincesOptions);
      return of();
    }
    if (typeof value == "string") {
      const filterValue = value.toLowerCase();
      this[arrResult] = this[arrNameToSearch].filter(x => x.nm.toLowerCase().includes(filterValue) || x.id == "00");
      return of();
    } else {
      this[arrResult] = this[arrNameToSearch].filter(x => x.nm.toLowerCase().includes(value.nm.toLowerCase()) || x.id == "00");
      return of();
    }

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.searchService.setSearchBHValue("");
    if(this.municipalitiesSubscription)this.municipalitiesSubscription.unsubscribe();
    if(this.provincesSubscription)this.provincesSubscription.unsubscribe();
    this.search$.unsubscribe();
  }

  search(category: string = null) {
    this.filterPosts();
  }
  goToProfile() {
    this.router.navigateByUrl('');
  }

  setCategoryFilter(category: string, search: boolean = null): void {
    category === this.selectedCategory
    ? this.selectedCategory = ''
    : this.selectedCategory = category;
    this.filterPosts();
  }

  onGoToCreate(): void {
    this.router.navigateByUrl('/product/create');
  }

  onGoToPost(postId: string): void {
    this.router.navigateByUrl(`/product/${postId}`);
  }

  onPress(direction: string = null) {
    console.log('onPress');
    this.stopInterval();
    this.startInterval(direction);
  }

  onPressUp() {
    console.log('onPressUp');
    this.stopInterval();
  }

  startInterval(direction: string = null) {
    this.interval = setInterval(() => {
      const maxScrollLeft = document.getElementById('categories').scrollWidth - document.getElementById('categories').clientWidth;
      console.log({ currentScroll: document.getElementById('categories').scrollLeft }, { position: this.position }, this.lastScroll);
      if (direction === 'left') {
        if (this.lastScroll >= 0) {
          console.log('esto es left?')
          document.getElementById('categories').scrollBy({ top: 0, left: -(this.lastScroll - 1), behavior: 'smooth'});
          const currentScroll = document.getElementById('categories').scrollLeft;
          this.lastScroll = currentScroll;
        }
        
      } 
      if (direction === null) {
        if (this.lastScroll < maxScrollLeft) {
          console.log('que mierda pasa?')
          document.getElementById('categories').scrollBy({ top: 0, left: (this.lastScroll + 1), behavior: 'smooth'});
          const currentScroll = document.getElementById('categories').scrollLeft;
          this.lastScroll = currentScroll;
        }
      }
    }, 200);
  }

  stopInterval() {
    clearInterval(this.interval);
  }
  isFavourited(id: string): boolean {
    return this.favouritedPosts[id] == undefined
      ? this.user.favouritePosts && this.user.favouritePosts.includes(id)
      : this.favouritedPosts[id]
    // return false;
  }
  toggleFavourite(e, id: string) {
    console.log(e);

    e.stopPropagation();
    this.favouritedPosts[id] != undefined
      ? this.favouritedPosts[id] = !this.favouritedPosts[id]
      : this.favouritedPosts[id] = !(this.user.favouritePosts && this.user.favouritePosts.includes(id));
    this.favouriteInProcess[id] = true;
    let favouritePostsArr: string[];
    if (this.user.favouritePosts) {
      favouritePostsArr = [...this.user.favouritePosts];
    } else {
      favouritePostsArr = [];
    }
    if (this.favouritedPosts[id]) {
      favouritePostsArr.push(id);
      this.userProvider.setUserField(this.user.id, 'favouritePosts', favouritePostsArr).then(_ => {
        this.user.favouritePosts = favouritePostsArr;
        this.favouriteInProcess[id] = false;
      }).catch(err => {
        this.favouritedPosts[id] = false;
        this.favouriteInProcess[id] = false;
        this.utilsService.showPrimeToast('error', '', "Ha ocurrido un error al añadir a favoritos, vuelva a inentarlo")
      });
    } else {
      favouritePostsArr.splice(favouritePostsArr.indexOf(id), 1);
      this.userProvider.setUserField(this.user.id, 'favouritePosts', favouritePostsArr).then(_ => {
        this.user.favouritePosts = favouritePostsArr;
        this.favouriteInProcess[id] = false;
      }).catch(err => {
        this.favouritedPosts[id] = true;
        this.favouriteInProcess[id] = false;
        this.utilsService.showPrimeToast('error', '', 'Ha ocurrido un error al eliminar de favoritos, vuelva a inentarlo');
      });
    }

  }
  async goToFilters() {
    this.filterPosts();
  }


  filterPosts(): void {
    const tempPosts: PostModel[] = JSON.parse(JSON.stringify(this.posts));
    const tempFilteredPosts = tempPosts.filter(post => (
      // this.filterByUbication(post.location) &&
      this.filterByProvince(post.province) &&
        this.filterByMunicipalitie(post.municipalitie) &&
      this.filterMaxPrice(post.price) &&
      this.filterMinPrice(post.price) &&
      this.filterByCategory(post.category) &&
      post.title.toLowerCase().includes(this.searchInput.toLowerCase())
    ));
    this.filters.order
    ? this.filteredPosts = tempFilteredPosts.sort(orderByFilterOrder(this.filters.order))
    : this.filteredPosts = tempFilteredPosts.reverse();
  }

  filterByUbication(ubication: string): boolean {
    return (typeof this.filters.ubication == 'undefined' || this.filters.ubication == 'Todas las provincias'
    || this.filters.ubication == ubication);
  }

  filterMinPrice(postPrice): boolean {
    return (typeof this.filters.minPrice == 'undefined' || postPrice >= this.filters.minPrice);
  }

  filterMaxPrice(postPrice): boolean {
    return (typeof this.filters.maxPrice == 'undefined' || postPrice <= this.filters.maxPrice);
  }

  filterByCategory(postCategory: string): boolean {
    return (this.selectedCategory == '' || this.selectedCategory == postCategory);
  }

  getCategoriesOrderedByWeight() {
    return this.categories.categories.slice(0).sort((a, b) => a.weight - b.weight);
  }

  getFilteredPostsByCategory(category: string) {
    return this.filteredPosts.filter(post => post.category === category);
  }

  limitFilteredPostsByCategory(category: string): PostModel[] {
    return this.filteredPosts.filter(post => post.category === category);

  }

  seeMoreOfCategory(category: string) {
    this.categoriesPagination[category] += 4;
  }

  watchMorePremiumPosts() {
    this.premiumPagination += 4;
  }

  getFilteredPremiumPostsLength() {
    return this.filteredPosts.filter(post => this.premiumUserIds.includes(post.userId)).length;
  }
  getPremiumPosts() {
    return this.filteredPosts.filter(post => this.premiumUserIds.includes(post.userId)).slice(0, this.premiumPagination);
  }

  // async goToDeleted(){
  //   const modal = await this.modalController.create({
  //     component: DeletedPostComponent
  //   });
  //   await modal.present();
  // }

  applyFilters() {
    const filtersToReturn = {};
    Object.keys(this.filters).forEach(key => {
      if (this.filters[key] != null) {
        filtersToReturn[key] = this.filters[key];
      }
    });
  }

  checkInput() {
    // if (!this.provinces.find(r => r == this.filters.ubication)) {
    //   this.filters.ubication = null;
    // }
  }

  getOrderText(key: string): string {
    return PostFilterOrders[key];
  }

  priceChanged() {
    // if(this.filters.minPrice > this.filters.maxPrice){
    //   this.range = [this.filters.maxPrice, this.filters.minPrice];
    // }else
      this.range = [this.rangeMinPrice, this.rangeMaxPrice];

  }

  sliderChanged() {
    this.filters.minPrice = this.range[0];
    this.filters.maxPrice = this.range[1];
    this.rangeMinPrice = this.range[0];
    this.rangeMaxPrice = this.range[1];
  }

  setOrder(order) {
    this.filters.order = order;
    return true;
  }
  cleanPriceFilter(){
    this.range = [0, this.maxPrice];
    this.sliderChanged();
    this.showPriceFilterMenu = false;
    this.filterPosts();
  }
  showPriceFilter(){
    this.tempMinPrice = this.range[0];
    this.tempMaxPrice = this.range[1];
    this.showPriceFilterMenu = true;
  }
  priceFilterResetPrevious(){
    console.log("lel");

    this.range[0] = this.tempMinPrice;
    this.range[1] = this.tempMaxPrice;
    this.showPriceFilterMenu = false;
    this.sliderChanged();
  }
  displayFn(province): string {
    return province && province.nm ? province.nm : '';
  }

  createForm() {
    return this.formBuilder.group({
      province: [this.filters.province || { id: '00', nm: 'Todas las provincias' }],
      municipalitie: [this.filters.municipalitie || { id: '00', nm: 'Todos los municipios' }],
    });
  }

  isInvalid(entry: string): boolean {
    return this.filterForm.get(entry).invalid && this.showErrors;
  }

  checkProvincesInput(){
    console.log(this.filterForm.get('municipalitie').value.id);
    console.log( this.filterForm.get('province').value.id);
    console.log(this.filterForm.get('municipalitie').value.id.indexOf(this.filterForm.get('province').value.id) == 0);

    if(this.filterForm.get('municipalitie').value.id && (this.filterForm.get('municipalitie').value.id.indexOf(this.filterForm.get('province').value.id) != 0
    || this.filterForm.get('province').value.id == "00")){
      console.log("WAT?!");

      this.filterForm.get('municipalitie').setValue({id: "00", nm: "Todos los municipios"});
      if(this.filterForm.get('province').value.id == "00"){
        this.filterForm
      }
    }
    this.municipalitiesToShow = this.municipalities.filter(municipalitie => municipalitie.id.indexOf(this.filterForm.get('province').value.id) == 0 || municipalitie.id == "00")
    console.log(this.municipalitiesToShow);

    if(this.municipalitiesSubscription)this.municipalitiesSubscription.unsubscribe();
    this.municipalitiesSubscription = this.filterForm.controls.municipalitie.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, 'municipalitiesToShow','municipalitiesOptions'))
    ).subscribe();
  }

  focus(){
    console.log("focus");
    this.tempUbicationProvince = this.filterForm.get('province').value;
    // this.filterForm.get('province').setValue('');
  }
  // blur(){
  //   console.log(this.filterForm.get('province').value);
  //   if(!this.filterForm.get('province').value.id)
  //     this.filterForm.get('province').setValue(this.tempUbicationProvince);
  // }

  checkMunicipalitiesInput(){
    if(!this.filterForm.get('municipalitie').value)return;
    if(!this.municipalities.find(r => r.nm == this.filterForm.get('municipalitie').value.nm)){
      this.filterForm.get('municipalitie').setValue(null);
    }
  }

  filterProvinces(){
    console.log("y aqui?");

    if(!this.filterForm.get('province').value)return this.provinces;
    if(!this.filterForm.get('province').value.nm)return this.provinces.filter(province => province.nm.toLowerCase().includes(this.filterForm.get('province').value));
    console.log("jum");

    return this.provinces.filter(province => province.nm.toLowerCase().includes(this.filterForm.get('province').value.nm));
  }

  filterMunicipalities(){
    if(!this.filterForm.get('municipalitie').value)return this.municipalities;
    if(!this.filterForm.get('municipalitie').value.nm)return this.municipalities.filter(municipalitie => municipalitie.nm.toLowerCase().includes(this.filterForm.get('municipalitie').value.toLowerCase()));
    return this.municipalities.filter(municipalitie => municipalitie.nm.toLowerCase().includes(this.filterForm.get('municipalitie').value.nm.toLowerCase()));
  }

  checkIfProvinceIsInCorrect(){
    let provinceFound;
    if(!this.filterForm.get('province').value){
      // this.provinceError = true;
      this.filterForm.controls.municipalitie.disable();
      return true;
    }else{
      if(!this.filterForm.get('province').value.nm){
        provinceFound = this.provinces.find(province => province.nm.toLowerCase() == this.filterForm.get('province').value.toLowerCase());
        if(!provinceFound){
          this.filterForm.controls.municipalitie.disable();
          this.provinceError = true;
          return true;
        }else{
          this.filterForm.get('province').setValue(provinceFound);
          this.checkProvincesInput();
          this.filterForm.controls.municipalitie.enable();
          this.provinceError = false;
          return false;
        }
      }
      if(this.filterForm.get('province').value.id !== '00'){
        this.filterForm.controls.municipalitie.enable();
        this.provinceError = false;
        return false;
      }else{
        // this.provinceError = true;
        this.provinceError = false;
        this.filterForm.controls.municipalitie.disable();
        return true;
      }
    }
  }
  checkIfMunicipalitieIsInCorrect(){
    let municipalitieFound;
    if(!this.filterForm.get('municipalitie').value){
      this.municipalitieError = true;
      return true;
    }else{
      if(!this.filterForm.get('municipalitie').value.nm){
        municipalitieFound = this.municipalities.find(municipalitie => municipalitie.nm.toLowerCase() == this.filterForm.get('municipalitie').value.toLowerCase());
        if(!municipalitieFound){
          this.municipalitieError = true;
          return true;
        }else{
          this.filterForm.get('municipalitie').setValue(municipalitieFound);
          this.municipalitieError = false;
          return false;
        }
      }
      this.municipalitieError = false;
      return false;
    }
  }

  checkUbicationFilters(){
    if(this.checkIfMunicipalitieIsInCorrect() || this.provinceError){
      this.showErrors = true;
      this.utilsService.showPrimeToast('error', '', "Los datos introducidos, no son válidos, vuelva a intentarlo");
      return;
    }
    if(this.filterForm.get('province').value){
      this.filters.province = this.filterForm.get('province').value;
    }else{
      this.filters.province = { id: '00', nm: 'Todas las provincias' };
    }
    if(this.filterForm.get('municipalitie').value){
      this.filters.municipalitie = this.filterForm.get('municipalitie').value;
    }else{
      this.filters.municipalities = { id: '00', nm: 'Todos los municipios' };
    }
    this.filterPosts();
  }

  cleanUbicationFilter(){
    this.filters.municipalitie = this.filterForm.get('municipalitie').value;
    this.filters.province = { id: '00', nm: 'Todas las provincias' };
    this.filterPosts();
  }

  filterByProvince(province: {id: string, nm: string}): boolean{
    return (typeof this.filters.province == "undefined" || this.filters.province.id == "00"
    || this.filters.province.id == province.id);
  }

  filterByMunicipalitie(municipalitie: {id: string, nm: string}): boolean{
    return (typeof this.filters.municipalitie == "undefined" || this.filters.municipalitie.id == "00"
    || this.filters.municipalitie.id == municipalitie.id);
  }

  closeProvinceAuto(){
    this.auto.closePanel();
  }

  preventCloseOnClickOut() {
    this.overlayContainer.getContainerElement().classList.add('disable-backdrop-click');
  }

  allowCloseOnClickOut() {
    this.overlayContainer.getContainerElement().classList.remove('disable-backdrop-click');
  }

  ubicationMenuClosed(){
    if(this.municipalitiesSubscription)this.municipalitiesSubscription.unsubscribe();
    if(this.provincesSubscription)this.provincesSubscription.unsubscribe();
  }
  ubicationMenuOpen(){
    this.provinces = new Provinces().provinces;
    this.municipalities = new Municipalities().municipalities;
    this.provinces.unshift({ id: '00', nm: 'Todas las provincias' });
    this.municipalities.unshift({ id: '00', nm: 'Todos los municipios' });
    this.filterForm = this.createForm();
    this.checkProvincesInput();
    if (!this.filters.province || this.filters.province.id == '00') {
      console.log("disable");

      this.filterForm.controls.municipalitie.disable();
    }
    this.provincesSubscription = this.filterForm.controls.province.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, 'provinces', 'provincesOptions'))
      ).subscribe();
  }

  async priceMenuOpen(){
    const mostExpensivePost = await this.postProvider.getTheMostExpensivePost().pipe(take(1)).toPromise();
    this.maxPrice = mostExpensivePost[0].price;
    Math.round(((this.maxPrice / 100) + Number.EPSILON) * 100) / 100;
    this.range = [this.filters.minPrice || 0, this.filters.maxPrice || this.maxPrice];
    this.rangeMinPrice = this.range[0];
    this.rangeMaxPrice = this.range[1];
  }

  priceMenuClosed(){

  }

  applyPriceFilter(){
    this.filters.minPrice = this.rangeMinPrice;
    this.filters.maxPrice = this.rangeMaxPrice;
    this.filterPosts();
  }

}
