import { UserModel } from '../schemes/models/user.model';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class UserProvider {

    constructor(private db: AngularFireDatabase) { }
    getUsers(): Observable<UserModel[]> {
      return this.db.list<UserModel>(`users`).valueChanges();
    }
  
    createUser(user: UserModel): Promise<void> {
      return this.db.object<UserModel>(`users/${user.id}`).set(user);
    }
  
    updateUser(user: UserModel): Promise<void> {
        return this.db.object<UserModel>(`users/${user.id}`).update(user);
    }
    getUserById(userId: string): Observable<UserModel> {
      return this.db.object<UserModel>(`users/${userId}`).valueChanges();
    }
  
    updateSubscriptions(userId: string, subscriptions): Promise<void> {
      return this.db.object<UserModel>(`users/${userId}/subscriptions`)
        .set(subscriptions);
    }   

    getUsersByField( fieldName: string, value: string | number, operation: "equalTo" | "startAt" | "endAt"):Observable<UserModel[]>{
      return this.db.list<UserModel>('users', ref => ref.orderByChild(fieldName)[operation](value)).valueChanges();
    }
    
    updateUserField(userId: string, fieldObj: any):Promise<void>{
      return this.db.object<UserModel>(`users/${userId}`).update(fieldObj);
    }
    setUserField(userId: string, fieldName: string, fieldValue: any):Promise<void>{
      return this.db.object<UserModel>(`users/${userId}/${fieldName}`).set(fieldValue);
    }
    
}
