import { PostProvider } from 'src/app/providers/post.provider';
import { Router, ActivatedRoute } from '@angular/router';
import { UserProvider } from './../../providers/user.provider';
import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../schemes/models/user.model';
import { AuthService } from '../../services/auth.service';
import { take } from 'rxjs/operators';
import { UtilsService } from '../../services/utils.service';
import { PostModel } from '../../../app/schemes/models/post.model';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  user: UserModel;
  showErrors = false;
  posts: PostModel[];
  favouriteInProcess: {[key: string]: boolean} = {};
  favouritedPosts: { [key: string]: boolean } = {};
  externalProfile: UserModel;

  constructor(
    private utilsService: UtilsService,
    private authService: AuthService,
    private userProvider: UserProvider,
    private postProvider: PostProvider,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.authService.getCurrentUser().pipe(take(1)).subscribe(async res => {
      this.user = res;
      this.externalProfile = await this.userProvider.getUserById(this.route.snapshot.params.id).pipe(take(1)).toPromise();
      this.posts = await this.postProvider.getUserPosts(this.externalProfile.id).pipe(take(1)).toPromise();
      console.log({posts: this.posts}, 'holaa?');
    })
  }

  onGoToPost(postId: string) {
    this.router.navigateByUrl(`/product/${postId}`);
  }

  onGoToEditUser() {
    // this.router.navigateByUrl(`/tabs/tab3/edit`);
  }


  isFavourited(id: string): boolean {
    return this.favouritedPosts[id] == undefined
      ? this.user && this.user.favouritePosts && this.user.favouritePosts.includes(id)
      : this.favouritedPosts[id]
  }
  toggleFavourite(e,id: string) {
    console.log(e);

    e.stopPropagation();
    this.favouritedPosts[id] != undefined
      ? this.favouritedPosts[id] = !this.favouritedPosts[id]
      : this.favouritedPosts[id] = !(this.user.favouritePosts && this.user.favouritePosts.includes(id));
    this.favouriteInProcess[id] = true;
    let favouritePostsArr: string[];
    if (this.user.favouritePosts)
      favouritePostsArr = [...this.user.favouritePosts];
    else
      favouritePostsArr = [];
    if (this.favouritedPosts[id]) {
      favouritePostsArr.push(id);
      this.userProvider.setUserField(this.user.id, "favouritePosts", favouritePostsArr).then(_ => {
        this.user.favouritePosts = favouritePostsArr;
        this.favouriteInProcess[id] = false;
      }).catch(err => {
        this.favouritedPosts[id] = false;
        this.favouriteInProcess[id] = false;
        this.utilsService.showPrimeToast('error', '', "Ha ocurrido un error al añadir a favoritos, vuelva a inentarlo")
      });
    } else {
      favouritePostsArr.splice(favouritePostsArr.indexOf(id), 1);
      this.userProvider.setUserField(this.user.id, "favouritePosts", favouritePostsArr).then(_ => {
        this.user.favouritePosts = favouritePostsArr;
        this.favouriteInProcess[id] = false;
      }).catch(err => {
        this.favouritedPosts[id] = true;
        this.favouriteInProcess[id] = false;
        this.utilsService.showPrimeToast('error', '', "Ha ocurrido un error al eliminar de favoritos, vuelva a inentarlo")
      });
    }

  }
}
