import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    data
    dataObservable: BehaviorSubject<any> = new BehaviorSubject('');
    // loading: HTMLIonLoadingElement;
    constructor(
        // private loadingController: LoadingController,
        // private toastCtrl: ToastController
        private db: AngularFireDatabase,
        private http: HttpClient,
        private messageService: MessageService,
        private spinnerService: NgxSpinnerService,
    ) { }

    setDataForNavigation<T>(data: any):void{
        this.dataObservable.next(data);
        this.data = JSON.parse(JSON.stringify(data));
    }
    getDataFromNavigation<T>(): any {
        return this.data;
    }

    getArrayFromObject(object: any): any[] {
        if (object)
            return Object.keys(object).map((key: string) => object[key]);
        return [];
    }

    generateID(): string {
        return this.db.createPushId();
    }

    slugToString(slug: string): string {
        return slug.split('_').map(word => this.capitalizeFirstLetter(word)).join(' ')
    }

    stringToSlug(...stringsToConvert: string[]): string {
        return stringsToConvert.join('_').replace(/ /g, '_').toLowerCase();
    }

    // loading(timeout: number = 500): Promise<void> {
    //     return new Promise((resolve, reject) => setTimeout(resolve, timeout));
    // }
    async uploadToStorage(fileDataOrLocation: string, ref: string, type): Promise<string> {
        const fileRef = await firebase.storage().ref(ref);
        let task;
        switch (type) {
          case "photo":
            task = await fileRef.putString(fileDataOrLocation, 'data_url');
            break;
    ​      case "camera": 
            task = await fileRef.putString(fileDataOrLocation, 'data_url');
            break;
    ​      case "jpeg": 
            task = await fileRef.putString(fileDataOrLocation, 'data_url');
            break;
        }
        return task.ref.getDownloadURL();
    }

    async deleteFileFromStorage(url: string): Promise<any> {
        return firebase.storage().refFromURL(url).delete();
    }

    async getServerTimeStamp() {
        const url = `${environment.cloudFunctionsUrl}currentTime`;
        const response =  await this.http.get(url).toPromise() as {timestamp: number};
        return response.timestamp;
    }

    async presentLoading(): Promise<unknown> {
        return await this.spinnerService.show();
    }

    dissmissLoading(): void {
        this.spinnerService.hide();
    }
    
    capitalizeFirstLetter(string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getLastNItems<T>(items: T[], n: number): T[] {
        return items.slice(-n);
    }

    formatDate(date: Date): string {
        const today = (new Date()).setHours(0, 0, 0, 0);
        const hoursAndSeconds = this.formatDateToHHMM(date);

        if (date.getTime() > today) {
            return hoursAndSeconds;
        } else {
            return date.getUTCDate() + "/"
                + (date.getUTCMonth() + 1) + "/"
                + date.getUTCFullYear() + " "
                + hoursAndSeconds;
        }
    }

    formatDateToHHMM(date: Date): string {
        return date.getUTCHours() + ":" + date.getUTCMinutes();
    }

    formatDateToDDMMYYYY(date: Date): string {
        return date.getUTCDate() + "/"
            + (date.getUTCMonth() + 1) + "/"
            + date.getUTCFullYear();
    }

    getTimesTamp(): number {
        return firebase.database.ServerValue.TIMESTAMP as number;
    }

    getRandomChars(number: number){
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        return ([...Array(number)].reduce((acc, current) => ([...acc, characters.charAt(Math.floor(Math.random() * characters.length))]), [])).join('');
    }

    showPrimeToast(severityOpt, summaryOpt, detailOpt) {
        return this.messageService.add({
            severity: severityOpt,
            summary: summaryOpt,
            detail: detailOpt,
        });
    }
}
