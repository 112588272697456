import { AngularFireDatabase } from '@angular/fire/database';

export class UserModel {
    id: string;
    name: string;
    email?: string;
    _fullname?: string;
    photoUrl?: string;
    nif?: string;
    phone?: string;
    address?: string;
    createdAt: number;
    token?: string;
    currentChatId: string;
    currentChatTab: boolean;
    chatsIds: string[];
    favouritePosts: string[];
    municipalitie?: {id: string, nm: string};
    province?: {id: string, nm: string};
    customIdName?: string;

    constructor(utils: AngularFireDatabase) {
        this.id = utils.createPushId();
        this.name = '';
        this._fullname = '';
        this.currentChatId = '';
        this.currentChatTab = false;
        this.chatsIds = [];
    }
}
