export class Provinces {
    provinces: { id: string, nm: string }[];
    constructor() {
        this.provinces = [
            {
                id: "02",
                nm: "Albacete"
            },
            {
                id: "03",
                nm: "Alicante/Alacant"
            },
            {
                id: "04",
                nm: "Almería"
            },
            {
                id: "01",
                nm: "Araba/Álava"
            },
            {
                id: "33",
                nm: "Asturias"
            },
            {
                id: "06",
                nm: "Badajoz"
            },
            {
                id: "07",
                nm: "Balears, Illes"
            },
            {
                id: "08",
                nm: "Barcelona"
            },
            {
                id: "48",
                nm: "Bizkaia"
            },
            {
                id: "09",
                nm: "Burgos"
            },
            {
                id: "39",
                nm: "Cantabria"
            },
            {
                id: "12",
                nm: "Castellón/Castelló"
            },
            {
                id: "51",
                nm: "Ceuta"
            },
            {
                id: "13",
                nm: "Ciudad Real"
            },
            {
                id: "15",
                nm: "Coruña, A"
            },
            {
                id: "16",
                nm: "Cuenca"
            },
            {
                id: "10",
                nm: "Cáceres"
            },
            {
                id: "11",
                nm: "Cádiz"
            },
            {
                id: "14",
                nm: "Córdoba"
            },
            {
                id: "20",
                nm: "Gipuzkoa"
            },
            {
                id: "17",
                nm: "Girona"
            },
            {
                id: "18",
                nm: "Granada"
            },
            {
                id: "19",
                nm: "Guadalajara"
            },
            {
                id: "21",
                nm: "Huelva"
            },
            {
                id: "22",
                nm: "Huesca"
            },
            {
                id: "23",
                nm: "Jaén"
            },
            {
                id: "24",
                nm: "León"
            },
            {
                id: "25",
                nm: "Lleida"
            },
            {
                id: "27",
                nm: "Lugo"
            },
            {
                id: "28",
                nm: "Madrid"
            },
            {
                id: "52",
                nm: "Melilla"
            },
            {
                id: "30",
                nm: "Murcia"
            },
            {
                id: "29",
                nm: "Málaga"
            },
            {
                id: "31",
                nm: "Navarra"
            },
            {
                id: "32",
                nm: "Ourense"
            },
            {
                id: "34",
                nm: "Palencia"
            },
            {
                id: "35",
                nm: "Las Palmas"
            },
            {
                id: "36",
                nm: "Pontevedra"
            },
            {
                id: "26",
                nm: "Rioja, La"
            },
            {
                id: "37",
                nm: "Salamanca"
            },
            {
                id: "38",
                nm: "Santa Cruz de Tenerife"
            },
            {
                id: "40",
                nm: "Segovia"
            },
            {
                id: "41",
                nm: "Sevilla"
            },
            {
                id: "42",
                nm: "Soria"
            },
            {
                id: "43",
                nm: "Tarragona"
            },
            {
                id: "44",
                nm: "Teruel"
            },
            {
                id: "45",
                nm: "Toledo"
            },
            {
                id: "46",
                nm: "Valencia/València"
            },
            {
                id: "47",
                nm: "Valladolid"
            },
            {
                id: "49",
                nm: "Zamora"
            },
            {
                id: "50",
                nm: "Zaragoza"
            },
            {
                id: "05",
                nm: "Ávila"
            }
        ]
    }
}
