import { LegalPageComponent } from './pages/legal-page/legal-page.component';
import { AuthChildGuard } from './guards/auth-child.guard';
import { PostsComponent } from './pages/posts/posts.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';
import { ChatsComponent } from './pages/admin/chats/chats.component';
import { PostComponent } from './pages/posts/post/post.component';
import { ProfileComponent } from './pages/admin/profile/profile.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ChatComponent } from './pages/admin/chats/chat/chat.component';
import { UserPostsComponent } from './pages/admin/user-posts/user-posts.component';
import { FavouritesComponent } from './pages/admin/favourites/favourites.component';
import { CreatePostComponent } from './pages/admin/user-posts/create-post/create-post.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';



const routes: Routes = [
  { path: '', component: MainComponent, children: [
      { path: 'products', component: PostsComponent },
      { path: 'product/:id', component: PostComponent},
      { path: 'profile/:id', component: UserProfileComponent},
      { path: 'legal-page/:subject', component: LegalPageComponent},
      {
        path: 'client', component: AdminComponent, canActivateChild: [AuthChildGuard], children: [
          { path: 'profile', component: ProfileComponent },
          { path: 'chats', component: ChatsComponent, children: [
            { path: ':id', component: ChatComponent },
          ]},
          { path: 'posts', component: UserPostsComponent },
          { path: 'posts/create', component: CreatePostComponent },
          { path: 'posts/:id', component: CreatePostComponent },
          { path: 'favourites', component: FavouritesComponent },
        ]
      },
    ],
  },
  { path: '', pathMatch: 'full', redirectTo: '/products'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
