import { MainComponent } from './pages/main/main.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS, MatMenuModule, MatSidenavModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { PostsComponent } from './pages/posts/posts.component';
import { PostComponent } from './pages/posts/post/post.component';
import { HeaderComponent } from './components/header/header.component';
import { ChatsComponent } from './pages/admin/chats/chats.component';
import { SliderModule } from 'primeng/slider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProfileComponent } from './pages/admin/profile/profile.component';
import { AdminComponent } from './pages/admin/admin.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ChatComponent } from './pages/admin/chats/chat/chat.component';
import { UserPostsComponent } from './pages/admin/user-posts/user-posts.component';
import { FavouritesComponent } from './pages/admin/favourites/favourites.component';
import { CreatePostComponent } from './pages/admin/user-posts/create-post/create-post.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import {SidebarModule} from 'primeng/sidebar';
import { FooterComponent } from './components/footer/footer.component';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LegalPageComponent } from './pages/legal-page/legal-page.component';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginModalComponent,
        HeaderComponent,
        PostsComponent,
        ChatsComponent,
        ChatComponent,
        PostComponent,
        ProfileComponent,
        AdminComponent,
        UserPostsComponent,
        FavouritesComponent,
        CreatePostComponent,
        CarouselComponent,
        UserProfileComponent,
        LegalPageComponent,
        FooterComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        ReactiveFormsModule,
        FormsModule,
        MatDialogModule,
        MatAutocompleteModule,
        SliderModule,
        MatMenuModule,
        SidebarModule,
        BrowserAnimationsModule,
        OverlayPanelModule,
        MatSidenavModule,
        MessagesModule,
        MessageModule,
        ToastModule,
        NgxSpinnerModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}, MessageService],
    entryComponents: [LoginModalComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
