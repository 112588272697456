import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserModel } from 'src/app/schemes/models/user.model';
import { take, startWith, map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Provinces } from 'src/app/schemes/models/provinces.model';
import { Subscription, of, Observable } from 'rxjs';
import { Municipalities } from 'src/app/schemes/models/municipalities.model';
import { UtilsService } from 'src/app/services/utils.service';
import { UserProvider } from 'src/app/providers/user.provider';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})

export class ProfileComponent implements OnInit, OnDestroy {
  user: UserModel;
  fbUser;
  userForm: FormGroup;
  selectedLocation: string;
  showErrors: boolean = false;
  provinces: {id: string, nm: string}[];
  municipalities: {id: string, nm: string}[];
  municipalitiesToShow: {id: string, nm: string}[] = [];
  provincesOptions: {id:string, nm: string}[] = [];
  municipalitiesOptions: {id:string, nm: string}[] = [];
  municipalitiesSubscription: Subscription;
  provincesSubscription: Subscription;
  municipalitieError = false;
  provinceError = false;
  edit = false;
  tempUser: UserModel;
  authSubscription: Subscription;

  constructor(
    private userProvider: UserProvider,
    private utilsService: UtilsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
  ) { }

  async ngOnInit() {
    this.authSubscription = this.authService.getCurrentUser().subscribe( res => {
        this.user = res;
        console.log({user: this.user})
        this.fbUser = this.authService.getCurrentFbUser();
        this.provinces = new Provinces().provinces;
        this.municipalities = new Municipalities().municipalities;
        this.userForm = this.createForm();
        this.checkProvincesInput();
        this.userForm.disable();
        this.provincesSubscription = this.userForm.controls.province.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value, 'provinces', 'provincesOptions'))
          ).subscribe();
        console.log({user: this.user}, {fbUser: this.fbUser}, {form: this.userForm});
    })
  }

  ngOnDestroy() {
    console.log("destroyeando");
    this.authSubscription.unsubscribe();
    if(this.municipalitiesSubscription)this.municipalitiesSubscription.unsubscribe();
    if(this.provincesSubscription)this.provincesSubscription.unsubscribe();
  }

  private _filter(value: string | {id: string, nm: string}, arrNameToSearch: string, arrResult): Observable<void> {
    if (!value) {
     this[arrResult] = this[arrNameToSearch];
     console.log(this.provincesOptions);
     return of();
    }
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      this[arrResult] = this[arrNameToSearch].filter(x => x.nm.toLowerCase().includes(filterValue));
      return of();
      } else {
      this[arrResult] = this[arrNameToSearch].filter(x => x.nm.toLowerCase().includes(value.nm.toLowerCase()));
      return of();
    }

  }

  createForm() {
    return this.formBuilder.group({
      name: [this.user.name ? this.user.name : '', [Validators.required]],
      email: [this.fbUser.email ? this.fbUser.email : '', [Validators.required]],
      photoUrl: [this.user.photoUrl ? this.user.photoUrl : ''],
      province: [this.user.province || ''],
      municipalitie: [this.user.municipalitie || ''],
      image: [this.user.photoUrl || '']
    });
  }

  isInvalid(entry: string): boolean {
    return this.userForm.get(entry).invalid && this.showErrors;
  }

  async onSaveUser() {
    this.utilsService.presentLoading();
    console.log(this.userForm);
    if (!this.userForm.valid) {
      console.log(this.userForm);
      this.showErrors = true;
      this.utilsService.showPrimeToast('error', '', "Los datos introducidos, no son válidos, vuelva a intentarlo");
      this.checkIfMunicipalitieIsInCorrect();
      return this.utilsService.dissmissLoading();
    }
    console.log(this.userForm);
    
    if(!this.userForm.valid && this.showErrors || this.checkIfMunicipalitieIsInCorrect() || this.provinceError){
      this.showErrors = true;
      this.utilsService.showPrimeToast('error', '', "Los datos introducidos, no son válidos, vuelva a intentarlo");
      this.utilsService.dissmissLoading();
      return;
    }
    console.log(this.userForm);

    await this.setFormData();
    // this.user.postsLocation = this.selectedLocation;
    await this.userProvider.updateUser(this.user)
    .then( _ => {
      console.log(this.userForm);
      this.utilsService.showPrimeToast('success', '', `Se ha modificado tu usuario correctamente`);
      this.utilsService.dissmissLoading();
      this.finalizeEditMode();
    })
    .catch(err => {
      this.utilsService.dissmissLoading();
      // this.utilsService.errorHandling('Hubo un problema al guardar los datos, vuelva a intentarlo')
    });
  }

  async setFormData() {
    this.user.name = this.userForm.get('name').value;
    this.fbUser.email !== this.userForm.get('email').value ? await this.authService.updateCurrentUserEmail(this.userForm.get('email').value) : null;
    this.user.province = this.userForm.get('province').value;
    this.user.municipalitie = this.userForm.get('municipalitie').value;
    this.user.customIdName = this.user.name.split(' ').map(word => word.split('')[0]).join('').toUpperCase();
  }

  async onUserPhoto(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = async (e: any) => {
      this.userForm.get('image').patchValue(e.target.result);
      const uploadedImage = await this.utilsService.uploadToStorage(this.userForm.get('image').value, `users/${this.user.id}/profile.jpeg`, 'jpeg')
      await this.userProvider.setUserField(this.user.id, 'photoUrl', uploadedImage);
      this.user.photoUrl = uploadedImage;
      this.utilsService.showPrimeToast('success', '', `Se ha modificado tu foto correctamente, ¡recuerda guardar los cambios!`)
    }
    reader.readAsDataURL(file);
  }

  async onPasswordRecovery() {
    const emailResponse = await this.authService.sendCustomResetPasswordEmail(this.fbUser.email);
    (emailResponse) ? this.utilsService.showPrimeToast('success', '', `¡se ha enviado un correo a ${this.fbUser.email} con un link para resetear tu contraseña!`) : null;
  }

  checkProvincesInput() {
    this.municipalitiesToShow = this.municipalities.filter(municipalitie => municipalitie.id.indexOf(this.userForm.get('province').value.id) == 0)
    if (this.municipalitiesSubscription) { this.municipalitiesSubscription.unsubscribe()};
    this.municipalitiesSubscription = this.userForm.controls.municipalitie.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, 'municipalitiesToShow', 'municipalitiesOptions'))
    ).subscribe();
  }

  checkMunicipalitiesInput() {
    if(!this.userForm.get('municipalitie').value) { return };
    if(!this.municipalities.find(r => r.nm == this.userForm.get('municipalitie').value.nm)){
      this.userForm.get('municipalitie').setValue(null);
    }
  }
  displayFn(province): string {
    return province && province.nm ? province.nm : '';
  }

  filterProvinces() {
    if (!this.userForm.get('province').value) {return this.provinces; }
    if (!this.userForm.get('province').value.nm) { return this.provinces.filter(province => province.nm.toLowerCase().includes(this.userForm.get('province').value))};
    return this.provinces.filter(province => province.nm.toLowerCase().includes(this.userForm.get('province').value.nm));
  }

  filterMunicipalities() {
    if (!this.userForm.get('municipalitie').value) { return this.municipalities; }
    if(!this.userForm.get('municipalitie').value.nm){ return this.municipalities.filter(municipalitie => municipalitie.nm.toLowerCase().includes(this.userForm.get('municipalitie').value.toLowerCase()))};
    return this.municipalities.filter(municipalitie => municipalitie.nm.toLowerCase().includes(this.userForm.get('municipalitie').value.nm.toLowerCase()));
  }

  // ngAfterViewChecked() {
  //   this.changeDetectorRef.detectChanges();
  // }

  checkIfProvinceIsInCorrect() {
    if (this.userForm.disabled) { return false};
    let provinceFound;
    if (!this.userForm.get('province').value){
      // this.provinceError = true;
      this.userForm.controls.municipalitie.disable();
      return true;
    } else {
      if (!this.userForm.get('province').value.nm) {
        provinceFound = this.provinces.find(province => province.nm.toLowerCase() == this.userForm.get('province').value.toLowerCase());
        if (!provinceFound) {
          this.userForm.controls.municipalitie.disable();
          this.provinceError = true;
          return true;
        } else {
          this.userForm.get('province').setValue(provinceFound);
          this.checkProvincesInput();
          this.userForm.controls.municipalitie.enable();
          this.provinceError = false;
          return false;
        }
      }
      this.userForm.controls.municipalitie.enable();
      this.provinceError = false;
      return false;
    }
  }
  checkIfMunicipalitieIsInCorrect(){
    let municipalitieFound;
    if (!this.userForm.get('municipalitie').value) {
      this.municipalitieError = true;
      return true;
    } else {
      if (!this.userForm.get('municipalitie').value.nm){
        municipalitieFound = this.municipalities.find(municipalitie => municipalitie.nm.toLowerCase() == this.userForm.get('municipalitie').value.toLowerCase());
        if(!municipalitieFound){
          this.municipalitieError = true;
          return true;
        }else{
          this.userForm.get('municipalitie').setValue(municipalitieFound);
          this.municipalitieError = false;
          return false;
        }
      }
      this.municipalitieError = false;
      return false;
    }
  }

  async finalizeEditMode() {
    console.log(this.userForm);
    this.userForm.controls.municipalitie.disable();
    this.userForm.disable();
    this.user = await this.authService.getCurrentUser().pipe(take(1)).toPromise();
    this.userForm = this.createForm();
    this.userForm.disable();
    this.edit = false;
  }

  goToEdit() {
    this.userForm.enable();
    this.userForm.controls.municipalitie.disable();
    this.edit = !this.edit;
  }
}
