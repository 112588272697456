export enum StockUnits {
    units = "Unidades",
    rolls = "Rollos",
    meters = "Metros",
    decimeters = "Decímetros",
    centimeters = "Centímetros",
    millimeters = "Milímetros",
    squareMeters = "m²",
    squareDecimeters = "dm²",
    squareCentimeters = "cm²",
    litres = "Litros",
    decilitres = "Decilitros",
    centilitres = "Centilitros",
    millilitres = "Mililitros",
    others = "Otra"
}