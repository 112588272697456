import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PostProvider } from 'src/app/providers/post.provider';
import { Subscription, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserProvider } from 'src/app/providers/user.provider';
import { PostModel } from 'src/app/schemes/models/post.model';
import { UtilsService } from 'src/app/services/utils.service';
import { UserModel } from 'src/app/schemes/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent implements OnInit {
  userSubscription: Subscription;
  user: UserModel;
  posts: PostModel[];
  authSubscription: Subscription;

  constructor(
    private postProvider: PostProvider,
    private authService: AuthService,
    private utilsService: UtilsService,
    private userProvider: UserProvider,
    private router: Router
  ) { }

  async ngOnInit() {
    this.authSubscription = this.authService.getCurrentUser().pipe(take(1)).subscribe(async res => {
      this.user = res;
      if (!this.user.favouritePosts) {
        this.posts = [];
      } else {
        const favouritePostsArrPromises = this.user.favouritePosts.map(favouritePostId => this.postProvider.getPostById(favouritePostId).pipe(take(1)).toPromise());
        this.posts = await Promise.all(favouritePostsArrPromises).catch(err => {
          this.utilsService.showPrimeToast('error', '', "Un error ha ocurrido, vuelva a intentarlo");
          return [];
        });
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.authSubscription.unsubscribe();
  }

  onGoToPost(postId: string): void {
    this.router.navigateByUrl(`/product/${postId}`);
  }

  removeFromFavourites(e, id: string) {
    const postIndex = this.posts.findIndex(post => post.id === id);
    const postToRemove: PostModel = this.posts[postIndex];
    this.posts.splice(postIndex, 1);
    e.stopPropagation();
    let favouritePostsArr: string[];
    favouritePostsArr = [...this.user.favouritePosts];
    favouritePostsArr.splice(postIndex, 1);
    this.userProvider.setUserField(this.user.id, 'favouritePosts', favouritePostsArr).then(_ => {
      this.user.favouritePosts = favouritePostsArr;
    }).catch(err => {
      this.posts.splice(postIndex, 0, postToRemove);
      this.utilsService.showPrimeToast('error', '', "Ha ocurrido un error al eliminar de favoritos, vuelva a inentarlo")
    });
  }

  getUserById(userId: string): Observable<UserModel> {
    return this.userProvider.getUserById(userId);
  }

}
