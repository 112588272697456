import { PaymentModel } from './../schemes/models/payment.model';
import { PostModel } from './../schemes/models/post.model';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PaymentProvider {

    constructor(private db: AngularFireDatabase) { }

    getActivePayments(){
        return this.db.list<PaymentModel>('payments', ref => ref.orderByChild('valid').equalTo(true)).valueChanges();
    }
}