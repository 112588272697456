import { MatDialog, MatDialogRef } from '@angular/material';
import { SearchService } from './../../../services/search.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ChatProvider } from './../../../providers/chat.provider';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/schemes/models/user.model';
import { PostModel } from 'src/app/schemes/models/post.model';
import { AuthService } from 'src/app/services/auth.service';
import { PostProvider } from 'src/app/providers/post.provider';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-user-posts',
  templateUrl: './user-posts.component.html',
  styleUrls: ['./user-posts.component.scss']
})
export class UserPostsComponent implements OnInit {
  user: UserModel;
  posts: PostModel[];
  filteredPosts: PostModel[];
  searchInput = '';
  showSearch = false;
  postsSold = false;
  authSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    // private utilsService: UtilsService,
    private authService: AuthService,
    private postProvider: PostProvider,
    private chatProvider: ChatProvider,
    private utilsService: UtilsService,
    private router: Router,
    private searchService: SearchService,
    private matDialog: MatDialog
  ) { }

  async ngOnInit() {
    this.authSubscription = this.authService.getCurrentUser().pipe(take(1)).subscribe(async res => {
      this.user = res;
      console.log(this.user);
      this.posts = await this.postProvider.getUserPosts(this.user.id).pipe(take(1)).toPromise();
      this.showSearch = false;
      (this.posts.length > 0) ? this.search() : null;
      console.log({ posts: this.posts }, 'holaa?');
      this.searchSubscription = this.searchService.getSearchBH().subscribe(res => {
        this.searchInput = res;
        this.search();
      })
    });
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
    this.searchSubscription.unsubscribe();
  }

  search(): void {
    console.log({ postsSold: this.postsSold }, { posts: this.filteredPosts })
    let tempPosts: PostModel[] = JSON.parse(JSON.stringify(this.posts));
    console.log("search");
    this.filteredPosts = tempPosts.filter(post => {
      if (
        ((this.postsSold && post.product.stock === 0 && (post.customId.toLowerCase().includes(this.searchInput.toLowerCase())
          || post.title.toLowerCase().includes(this.searchInput.toLowerCase()))) ||
          (this.postsSold === false && post.product.stock > 0 &&
            (post.customId.toLowerCase().includes(this.searchInput.toLowerCase())
              || post.title.toLowerCase().includes(this.searchInput.toLowerCase()))
          ))) { return post; }
    }).reverse();
    console.log(this.filteredPosts);
  }

  trackByItems(index: number, post: PostModel): string { return post.id; }

  onGoToPost(postId: string) {
    this.router.navigateByUrl(`/client/posts/${postId}`);
  }

  async onDeletePost(post: PostModel, dialogRef) {
    const dialog = this.matDialog.open(dialogRef, {
      width: '500px',
      // height: '500px',
      panelClass: 'custom-confirm-dialog'
    });
    const confirmation = await dialog.afterClosed().pipe(take(1)).toPromise();
    if (confirmation) {
      const promisesArr = post.photoUrls.map(photo => this.utilsService.deleteFileFromStorage(photo));
      let idChats;
      if (post.chats) {
        idChats = JSON.parse(JSON.stringify(post.chats));
      }
      this.postProvider.deletePost(post.id).then(async _ => {
        await Promise.all(promisesArr).catch(err => { });
        if (idChats) {
          const updateFieldsPromises = idChats.map(id => this.chatProvider.updateChat(id, { deletedTitle: post.title }));
          await Promise.all(updateFieldsPromises).catch(err => { });
        }
      });
    }
  }
}
