import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-page',
  templateUrl: './legal-page.component.html',
  styleUrls: ['./legal-page.component.scss'],
})
export class LegalPageComponent implements OnInit {

  content: {[key: string]: {text: string, title: string}} = {
    privacy: {text: '', title: 'Política de privacidad'},
    cookies: {text: '', title: 'Política de cookies'},
    userTerms: {text: '', title: 'Términos de uso'}
  };
  subject: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.subject = this.route.snapshot.params.subject;
    console.log({subject: this.subject});
  }
  goBack() {
    this.router.navigateByUrl('/products');
  }

}
