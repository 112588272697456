import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
    @Input() photos: string[];
    @Input() title: string;
    @Output() imageClick: EventEmitter<string> = new EventEmitter<string>();
    activePhotoIndex: number = 0;
    photoInterval;

    constructor() { }

    ngOnInit() {
        this.photoInterval = setInterval(_ => {
            (this.activePhotoIndex < this.photos.length - 1)
                ? this.activePhotoIndex++
                : this.activePhotoIndex = 0;
        }, 7000);
    }

    setActivePhotoIndex(newIndex: number) {
        this.activePhotoIndex = newIndex;
        clearInterval(this.photoInterval);
    }

    setPhotoIndex(operation: 'add' | null = null) {
        if (operation === 'add') {
            this.activePhotoIndex = (this.activePhotoIndex === (this.photos.length - 1)) ? 0 : (this.activePhotoIndex + 1);
        } else {
            this.activePhotoIndex = (this.activePhotoIndex === 0) ? this.photos.length - 1 : this.activePhotoIndex - 1;
        }
    }

    async showPhoto(photo: string) {
        this.imageClick.emit(photo);
    }

}
