import { MatDialog } from '@angular/material';
import { PostModel, Category } from 'src/app/schemes/models/post.model';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Provinces } from 'src/app/schemes/models/provinces.model';
import { AngularFireDatabase } from '@angular/fire/database';
import { Categories } from './../../../../schemes/models/categories.model';
import { take, startWith, map } from 'rxjs/operators';
import { UserModel } from 'src/app/schemes/models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostProvider } from 'src/app/providers/post.provider';
import { Observable, of, Subscription } from 'rxjs';
import { Municipalities } from 'src/app/schemes/models/municipalities.model';
import { StockUnits } from 'src/app/schemes/enums/stock-units.enum';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss']
})
export class CreatePostComponent implements OnInit {
  post: PostModel;
  postForm: FormGroup;
  user: UserModel;
  fbUser;
  showErrors: boolean = false;
  edit: boolean = false;
  categories: Category[];
  photos: string[] = [];
  photoError: boolean = false;
  stockUnits: string[];
  preventProvinceBlur: boolean = false;
  provinces: {id: string, nm: string}[];
  municipalities: {id: string, nm: string}[];
  municipalitiesToShow: {id: string, nm: string}[] = [];
  provincesOptions: {id:string, nm: string}[] = [];
  municipalitiesOptions: {id:string, nm: string}[] = [];
  municipalitiesSubscription: Subscription;
  provincesSubscription: Subscription;
  municipalitieError: boolean = false;
  provinceError: boolean = false;
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private postProvider: PostProvider,
    private utilsService: UtilsService,
    private angularFireDatabase: AngularFireDatabase,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog
  ) { }

  async ngOnInit() {
    this.provinces = new Provinces().provinces;
    this.municipalities = new Municipalities().municipalities;
    this.stockUnits = Object.keys(StockUnits);
    this.user = await this.authService.getCurrentUser().pipe(take(1)).toPromise();
    this.fbUser = await this.authService.getCurrentFbUser();
    const id = this.route.snapshot.params.id;
    this.edit = (id) ? true : false;
    this.post = (this.edit) ? await this.postProvider.getPostById(id).pipe(take(1)).toPromise() : new PostModel(this.angularFireDatabase);
    this.categories = new Categories().categories;
    this.photos = (this.post.photoUrls) ? [...Array(8 - this.post.photoUrls.length)].map((_, i) => '') : [...Array(8)].map((_, i) => '');
    this.post.photoUrls = this.post.photoUrls && this.post.photoUrls.length > 0 ? this.post.photoUrls : [];
    this.postForm = await this.createForm();
    this.checkProvincesInput();
    this.postForm.controls.municipalitie.disable();
    this.provincesSubscription = this.postForm.controls.province.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, 'provinces', 'provincesOptions'))
      ).subscribe();
    console.log(this.postForm);
    console.log(this.post.photoUrls);
  }


  private _filter(value: string | {id: string, nm: string}, arrNameToSearch: string, arrResult): Observable<void> {
    console.log(value);

    console.log(arrNameToSearch);

  console.log("lle´go");
    if (!value) {
     this[arrResult] = this[arrNameToSearch];
     console.log(this.provincesOptions);
     return of();
    }
    console.log("juma");

    if (typeof value == "string") {
      const filterValue = value.toLowerCase();
      this[arrResult] = this[arrNameToSearch].filter(x => x.nm.toLowerCase().includes(filterValue));
      return of();
      } else {
      this[arrResult] = this[arrNameToSearch].filter(x => x.nm.toLowerCase().includes(value.nm.toLowerCase()));
        return of();
    }

  }

  async createForm() {
    // const photoUrls = (this.post.photoUrls && this.post.photoUrls.length == 0) ? [Validators.required, Validators.minLength(1)] : null;
    return this.formBuilder.group({
      title: [this.post.title ? this.post.title : '', [Validators.required]],
      productBrand: [this.post.product.brand ? this.post.product.brand : ''],
      productModel: [this.post.product.model ? this.post.product.model : ''],
      productPrice: [this.post.product.price ? this.post.product.price : '', [Validators.pattern("(\\d+(\\.\\d+)?$)?")]],
      category: [this.post.category ? this.categories.find(category => category.name === this.post.category).name : '', [Validators.required]],
      price: [this.post.price ? this.post.price : '', [Validators.required, Validators.pattern("(\\d+(\\.\\d+)?$)?")]],
      phone: [this.post.phone ? this.post.phone : ''],
      stock: [this.post.product.stock , [Validators.pattern("\\d+")]],
      stockUnit: [this.post.product.stockUnit ? this.post.product.stockUnit : ''],
      // photoUrls: [this.post.photoUrls ? this.post.photoUrls : [], photoUrls],
      description: [this.post.description ? this.post.description : ''],
      province: [this.post.province || this.user.province, [Validators.required]],
      municipalitie: [this.post.municipalitie || this.user.municipalitie,  [Validators.required]],
      customId: [this.post.customId || await this.createCustomId()]
    });
  }

  isInvalid(entry: string): boolean {
    return this.postForm.get(entry).invalid && this.showErrors;
  }

  isValidPhotos() {
    return this.photos.some(photo => photo !== '');
  }

  async onSavePost() {
    this.utilsService.presentLoading();
    if (!this.postForm.valid) {
      this.showErrors = true;
      this.utilsService.showPrimeToast('error', 'Hubo un error', 'Los datos introducidos, no son válidos, vuelva a intentarlo');
      this.checkIfMunicipalitieIsInCorrect();
      this.utilsService.dissmissLoading();
      return;
    }
    if (!this.postForm.valid && this.showErrors || this.checkIfMunicipalitieIsInCorrect() || this.provinceError) {
      this.showErrors = true;
      this.utilsService.showPrimeToast('error', 'Hubo un error', 'Los datos introducidos, no son válidos, vuelva a intentarlo');
      this.utilsService.dissmissLoading();
      return;
    }
    if ((this.photos.filter(photo => photo !== '').length === 0) && (this.post.photoUrls.length === 0)) {
      this.showErrors = true;
      this.photoError = true;
      this.utilsService.dissmissLoading();
      return;
    } else { this.photoError = false; }
    await this.setFormData();
    const response = (this.edit) ? this.postProvider.updatePost(this.post) : this.postProvider.createPost(this.post);
    response.then(_ => {
      this.utilsService.showPrimeToast('success', 'Se ha creado el post correctamente', '');
      console.log("creado correctamente");

      this.router.navigateByUrl('/client/posts');
      this.utilsService.dissmissLoading();
    })
    .catch(err => {
      this.utilsService.dissmissLoading();
    });
  }


  async setFormData() {
    this.post.title = this.postForm.get('title').value;
    this.post.product.brand = this.postForm.get('productBrand').value;
    this.post.product.model = this.postForm.get('productModel').value;
    this.post.product.price = this.postForm.get('productPrice').value;
    this.post.category = this.postForm.get('category').value;
    this.post.price = this.postForm.get('price').value;
    this.post.phone = this.postForm.get('phone').value;
    if (this.postForm.get('stock').value === 0 || this.postForm.get('stock').value !== '' && this.postForm.get('stock').value != null){
      console.log("nulo");
      this.post.product.stock = this.postForm.get('stock').value;
    } else {
      this.post.product.stock = 1;
    }
    this.post.product.stockUnit = this.postForm.get('stockUnit').value;
    this.post.description = this.postForm.get('description').value;
    this.post.userId = this.user.id;
    this.post.photoUrls = [...this.post.photoUrls, ...await this.uploadPhotos()];
    this.post.createdAt = this.post.createdAt ? this.post.createdAt : Date.now();
    this.post.province = this.postForm.get('province').value;
    this.post.municipalitie = this.postForm.get('municipalitie').value;
    this.post.customId = await this.createCustomId();
  }

  async createCustomId() {
    const customId = `${this.user.customIdName}${this.utilsService.getRandomChars(4)}`;
    const isFound = await this.postProvider.findCustomIdPost(customId).pipe(take(1)).toPromise();
    return isFound.length > 0 ? this.createCustomId() : customId;
  }

  async showPhoto(photo: string) {
    // const photoModal = await this.modalController.create({
    //   component: PhotoModalComponent,
    //   componentProps: {
    //     photo,
    //  }
    // });
    // await photoModal.present();
  }

  async onCamera(event) {
    // const cameraOptions = await this.utilsService.actionSheetCameraOptions();
    // const imagePreview = await this.camera.getPicture(cameraOptions);
    // if (!this.photos.includes('data:image/jpeg;base64,' + imagePreview)) {
    //   this.photos[this.photos.findIndex(photo => photo === '')] = ('data:image/jpeg;base64,' + imagePreview);
    // }

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = async (e: any) => {
      if (!this.photos.includes(e.target.result)) {
        this.photos[this.photos.findIndex(photo => photo === '')] = (e.target.result);
      }
    }
    const fileBlob = reader.readAsDataURL(file);
    console.log({fileBlob}, {photos: this.photos});
  }

  async uploadPhotos(): Promise<string[]> {
    const photos = this.photos.filter(photo => photo !== '');
    if (photos.length > 0) {
      const uploadPromises = photos.map((photo, index) => {
          const photoUrlsLength = (this.post.photoUrls.length - 1) >= 0 ? this.post.photoUrls.length : 0;
          console.log({photoUrlsLength}, {photo});
          return this.utilsService.uploadToStorage(photo, `posts/${this.post.id}/photo_${photoUrlsLength + index + 1}.jpeg`, 'jpeg');
      });
      console.log({uploadPromises});
      const filesUploaded = await Promise.all(uploadPromises);
      return filesUploaded.length > 0 ? filesUploaded : [];
    }
    return [];
  }

  async deletePhoto(photo: string, i: number, collection: string, deleteDialogRef) {
    const dialog = this.matDialog.open(deleteDialogRef, {
      width: '500px',
      panelClass: 'custom-confirm-dialog'
    });
    const photoResponse = await dialog.afterClosed().pipe(take(1)).toPromise();
    collection === 'photos' && photoResponse ? this.photos.splice(i, 1) : null;
    collection === 'photos' && photoResponse ? this.photos.push('') : null;
    if (collection === 'photoUrls' && photoResponse) {
      await this.utilsService.deleteFileFromStorage(this.post.photoUrls[i]).catch(e => console.log(e));
      this.post.photoUrls.splice(i, 1);
      this.postProvider.setPostPhotoUrls(this.post.id, this.post.photoUrls);
    }
  }

  async onInfoButton(info: string, infoDialogRef) {
    let message: string;
    switch (info) {
      case 'customId':
        message = `Este es un identificador generado por Chispa para identificar tu producto. Lo puedes usar para organizar tus productos en el almacén junto con este número. De esa forma un cliente podrá preguntarte por un producto usando este identificador o a la Inversa y así tú tendrás claro a que producto hace referencia.`
        break;
      case 'province':
        message = `Indica en que provincia se encuentra tú producto. Esto ayudará a otros usuarios a encontrarlo más fácil.`;
        break;
      case 'municipalitie':
        message = `Indica en que municipio se encuentra tú producto. Esto ayudará a otros usuarios a encontrarlo más fácil.`
        break;
      default:
        message = '';
        break;
      }
    const dialog = this.matDialog.open(infoDialogRef, {
      width: '500px',
      panelClass: 'custom-confirm-dialog',
      data: message
    });
  }

  categorySelected(category: Category) {
    this.postForm.get('category').patchValue(category.name);
  }

  ngOnDestroy(): void {
    this.utilsService.setDataForNavigation('');
    if (this.municipalitiesSubscription) { this.municipalitiesSubscription.unsubscribe(); }
    if (this.provincesSubscription) { this.provincesSubscription.unsubscribe(); }
  }

  goBack(){
    this.router.navigateByUrl('/client/products');
  }

  getStockUnitText(key: string): string{
    return StockUnits[key];
  }

  checkProvincesInput(){
    this.municipalitiesToShow = this.municipalities.filter(municipalitie => municipalitie.id.indexOf(this.postForm.get('province').value.id) == 0)
    if(this.municipalitiesSubscription)this.municipalitiesSubscription.unsubscribe();
    this.municipalitiesSubscription = this.postForm.controls.municipalitie.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value, 'municipalitiesToShow','municipalitiesOptions'))
    ).subscribe();
  }

  checkMunicipalitiesInput(){
    if(!this.postForm.get('municipalitie').value)return;
    if(!this.municipalities.find(r => r.nm == this.postForm.get('municipalitie').value.nm)){
      this.postForm.get('municipalitie').setValue(null);
    }
  }
  displayFn(province): string {
    return province && province.nm ? province.nm : '';
  }

  filterProvinces(){
    console.log("y aqui?");

    if(!this.postForm.get('province').value)return this.provinces;
    if(!this.postForm.get('province').value.nm)return this.provinces.filter(province => province.nm.toLowerCase().includes(this.postForm.get('province').value));
    console.log("jum");

    return this.provinces.filter(province => province.nm.toLowerCase().includes(this.postForm.get('province').value.nm));
  }

  filterMunicipalities(){
    if(!this.postForm.get('municipalitie').value)return this.municipalities;
    if(!this.postForm.get('municipalitie').value.nm)return this.municipalities.filter(municipalitie => municipalitie.nm.toLowerCase().includes(this.postForm.get('municipalitie').value.toLowerCase()));
    return this.municipalities.filter(municipalitie => municipalitie.nm.toLowerCase().includes(this.postForm.get('municipalitie').value.nm.toLowerCase()));
  }
  ngAfterViewChecked(){
    console.log("after");

    this.changeDetectorRef.detectChanges();
  }
  checkIfProvinceIsInCorrect(){
    let provinceFound;
    if(!this.postForm.get('province').value){
      // this.provinceError = true;
      this.postForm.controls.municipalitie.disable();
      return true;
    }else{
      if(!this.postForm.get('province').value.nm){
        provinceFound = this.provinces.find(province => province.nm.toLowerCase() == this.postForm.get('province').value.toLowerCase());
        if(!provinceFound){
          this.postForm.controls.municipalitie.disable();
          this.provinceError = true;
          return true;
        }else{
          this.postForm.get('province').setValue(provinceFound);
          this.checkProvincesInput();
          this.postForm.controls.municipalitie.enable();
          this.provinceError = false;
          return false;
        }
      }
      this.postForm.controls.municipalitie.enable();
      this.provinceError = false;
      return false;
    }
  }
  checkIfMunicipalitieIsInCorrect(){
    let municipalitieFound;
    if(!this.postForm.get('municipalitie').value){
      this.municipalitieError = true;
      return true;
    }else{
      if(!this.postForm.get('municipalitie').value.nm){
        municipalitieFound = this.municipalities.find(municipalitie => municipalitie.nm.toLowerCase() == this.postForm.get('municipalitie').value.toLowerCase());
        if(!municipalitieFound){
          this.municipalitieError = true;
          return true;
        }else{
          this.postForm.get('municipalitie').setValue(municipalitieFound);
          this.municipalitieError = false;
          return false;
        }
      }
      this.municipalitieError = false;
      return false;
    }
  }

}
