import { MatDialog } from '@angular/material';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { take, first } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel } from 'src/app/schemes/models/user.model';
import { ChatModel } from 'src/app/schemes/models/chat.model';
import { PostModel } from 'src/app/schemes/models/post.model';
import { PostProvider } from 'src/app/providers/post.provider';
import { ChatProvider } from 'src/app/providers/chat.provider';
import { Title, Meta } from '@angular/platform-browser';
import { StockUnits } from 'src/app/schemes/enums/stock-units.enum';
import { UserProvider } from 'src/app/providers/user.provider';
import { ChatService } from 'src/app/services/chat.service';
import { LoginModalComponent } from 'src/app/components/login-modal/login-modal.component';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  public post: PostModel;
  user: UserModel;
  photos: string[] = [];
  edit = false;
  favourited = false;
  public favouriteInProcess: boolean;
  postUser: UserModel;
  noChat = false;
  constructor(
    private authService: AuthService,
    private postProvider: PostProvider,
    private utilsService: UtilsService,
    private chatProvider: ChatProvider,
    private chatService: ChatService,
    private userProvider: UserProvider,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog
  ) { }

  async ngOnInit() {
    this.user = await this.authService.getCurrentUser().pipe(take(1)).toPromise();
    if (this.user && this.user.favouritePosts && this.user.favouritePosts.includes(this.route.snapshot.params.id)) {
      this.favourited = true;
    }
    this.post = await this.postProvider.getPostById(this.route.snapshot.params.id).pipe(take(1)).toPromise();
    this.edit = this.user && this.user.id === this.post.userId ? true : false;
    this.postUser = await this.userProvider.getUserById(this.post.userId).pipe(take(1)).toPromise();
    console.log({ post: this.post }, { postUser: this.postUser })
    // this.noChat = this.utilsService.getDataFromNavigation().fromChat || false;
    this.title.setTitle(this.post.title);
    this.meta.addTags([
      { name: 'twitter:card', content: 'summary' },
      { name: 'og:url', content: `/post/${this.post.id}` },
      { name: 'og:title', content: this.post.title },
      { name: 'og:description', content: this.post.description },
      { name: 'og:image', content: this.post.photoUrls[0] }
    ]);
  }


  // async onShowPhoto(photo) {
  //   const photoModal = await this.modalController.create({
  //     component: PhotoModalComponent,
  //     componentProps: {
  //       photo,
  //     }
  //   });
  //   await photoModal.present();
  // }

  async onDeletePost() {
    // const modal = await this.modalController.create({component:DeletePostComponent,
    //   showBackdrop: false,
    //   backdropDismiss: false,
    //   cssClass: "delete-modal"
    // });
    // await modal.present();
    // const response = await modal.onDidDismiss();
    const confirmation = confirm(`¿Estás seguro que desea borrar este producto?`);
    if (confirmation) {
      const promisesArr = this.post.photoUrls.map(photo => this.utilsService.deleteFileFromStorage(photo));
      let idChats;
      if (this.post.chats) {
        idChats = JSON.parse(JSON.stringify(this.post.chats));
      }
      this.postProvider.deletePost(this.post.id).then(async _ => {
        await Promise.all(promisesArr).catch(err => { });
        if (idChats) {
          const updateFieldsPromises = idChats.map(id => this.chatProvider.updateChat(id, { deletedTitle: this.post.title }));
          await Promise.all(updateFieldsPromises).catch(err => { });
        }
      });
    }
  }

  async goToChat(postId: string = null) {
    if (!this.user) {
      return this.matDialog.open(LoginModalComponent, {
        width: '662px',
        height: '620px',
        data: {
          mode: `/product/${postId}`
        },
        panelClass: 'no-padding-modal',
      });
    }
    let componentProps = {};
    let chatExist = false;
    const userChats = this.chatService.getUserChats().getValue();
    let chat: any;
    let chat3: any;
    if (this.post.chats) {
      chat = userChats.find(chat => this.post.chats.includes(chat.chat.id) && !chat.chat.closed);
    } else {
      chat = null;
    }
    if (chat) {
      chatExist = true;
      console.log("como que true?");

      // console.log(chat);
      // componentProps = {
      //   chat: chat.chat,
      //   product: this.post.title,
      //   productImg: (this.post.photoUrls && this.post.photoUrls.length > 0) ? this.post.photoUrls[0] : null,
      //   price: this.post.price,
      //   customId: this.post.customId,
      //   fromPost: true
      // };
    } else {
      console.log("menos al, es false");

      const chatId = this.chatProvider.getRandomPushKey();
      chat3 = {
        id: chatId, createdAt: Date.now(), updatedAt: Date.now(), totalMessages: 0, lastMessage: "",
        readMessages: { [this.authService.getCurrentUserUid()]: 0, [this.post.userId]: 0 },
        members: [{ userId: this.authService.getCurrentUserUid(), storedChat: false }, { userId: this.post.userId, storedChat: false }], title: this.post.title, postId: this.post.id
      };
      console.log("creando wat?");

      this.chatProvider.createChat(chat3).then(_ => {
        console.log("creando2 por la cara");

        const chat2 = chat3;
        // this.disableChat = false;
        this.postProvider.getPostById(this.post.id).pipe(take(1)).toPromise().then(res => {
          if (!res.chats) res.chats = [];
          res.chats.push(chat2.id);
          this.postProvider.updatePostField(res.id, { chats: res.chats })
        })
        if (!this.user.chatsIds) {
          this.user.chatsIds = [];
        }
        console.log(this.user.chatsIds);

        this.user.chatsIds.push(chatId);
        this.userProvider.updateUserField(this.authService.getCurrentUserUid(), { chatsIds: this.user.chatsIds });
        if (!chat2) {
          // this.postProvider.getPostById(this.postId).pipe(take(1)).toPromise().then(res => {
          this.userProvider.getUserById(this.post.id).pipe(take(1)).toPromise().then(user => {
            let strangerChatsIds = user.chatsIds;
            if (!strangerChatsIds) strangerChatsIds = [];
            strangerChatsIds.push(chatId)
            this.userProvider.updateUserField(this.post.userId, { chatsIds: strangerChatsIds });
          })
        } else {
          const strangerId = chat2.members.find(member => member.userId === this.post.userId);
          this.userProvider.getUserById(strangerId.userId).pipe(take(1)).toPromise().then(user => {
            let strangerChatsIds = user.chatsIds;
            if (!strangerChatsIds) strangerChatsIds = [];
            strangerChatsIds.push(chatId)
            this.userProvider.updateUserField(this.post.userId, { chatsIds: strangerChatsIds });
          })
        }
        // this.sendMessage(tempMessage, false);
      }).catch(e => {
        this.utilsService.showPrimeToast('error', '', "Ha ocurrido un error, vuelva a intentarlo");
      })
    }
    this.utilsService.setDataForNavigation(
      {
        chat: chatExist ? chat.chat : chat3,
        product: this.post.title,
        productImg: (this.post.photoUrls && this.post.photoUrls.length > 0) ? this.post.photoUrls[0] : null,
        price: this.post.price,
        customId: this.post.customId,
        fromPost: true
      });

    // this.router.navigateByUrl(`/client/chats/${chat.}`);
    console.log(chat);
    console.log(chat3);

    const routerId = chatExist ? chat.id : chat3.id;
    this.router.navigateByUrl(`/client/chats/${chatExist ? chat.id : chat3.id}`);
  }

  goBack() {
    console.log("back");
    this.router.navigateByUrl('/');
  }

  goToPost(postId: string) {
    this.router.navigateByUrl(`/client/posts/${postId}`);
  }

  toggleFavourite() {
    this.favourited = !this.favourited;
    this.favouriteInProcess = true;
    let favouritePostsArr: string[];
    if (this.user.favouritePosts)
      favouritePostsArr = [...this.user.favouritePosts];
    else
      favouritePostsArr = [];
    if (this.favourited) {
      favouritePostsArr.push(this.post.id);
      this.userProvider.setUserField(this.user.id, "favouritePosts", favouritePostsArr).then(_ => {
        this.user.favouritePosts = favouritePostsArr;
        this.favouriteInProcess = false;
      }).catch(err => {
        this.favourited = false;
        this.favouriteInProcess = false;
        this.utilsService.showPrimeToast('error', '', "Ha ocurrido un error al añadir a favoritos, vuelva a inentarlo")
      });
    } else {
      favouritePostsArr.splice(favouritePostsArr.indexOf(this.post.id), 1);
      this.userProvider.setUserField(this.user.id, "favouritePosts", favouritePostsArr).then(_ => {
        this.user.favouritePosts = favouritePostsArr;
        this.favouriteInProcess = false;
      }).catch(err => {
        this.favourited = true;
        this.favouriteInProcess = false;
        this.utilsService.showPrimeToast('error', '', "Ha ocurrido un error al eliminar de favoritos, vuelva a inentarlo")
      });;
    }

  }
  goToUserProfile() {
    // this.utilsService.setDataForNavigation({userId: this.post.userId,notMyProfile:true });

    this.router.navigateByUrl(`/profile/${this.postUser.id}`);

  }
  isFavourited(id: string): boolean {
    return this.favourited == undefined
      ? this.user && this.user.favouritePosts && this.user.favouritePosts.includes(id)
      : this.favourited
  }

  getPostProductStockUnit(stockValue: string): string {
    return stockValue ? StockUnits[stockValue] : '';
  }
}

