import { MessageModel } from './../schemes/models/message.model';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class MessageProvider {

    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
    ) {}
    getRandomPushKey(): string {
        return this.db.createPushId();
    }

    getMessagesByChatId(chatId: string): Observable<MessageModel[]>{
        return this.db.list<MessageModel>('messages', ref => ref.orderByChild('chatId').equalTo(chatId)).valueChanges();
    }
     createMessage(message: MessageModel){
         
         return this.db.object<MessageModel>(`messages/${message.id}`).set(message);
     }
     sendMessage(text: string, token: string, title: string, id_match: string, foreground: boolean):Promise<Object>{
        let payload: any = {
            notification: {
              title: title,
              body: text,
              sound: "default",
              icon: "notification_icon"
            },
            data: {
              body: id_match,
              type: 'notification',
            }
          };
          if (foreground){
            payload.data.notification_foreground = 'true';
          }
          const url = `${environment.cloudFunctionsUrl}sendPushNotification`;;
            let body: any = {
              token: token,
              payload: payload
            }
            return this.http.post(url, body).toPromise();
     }
}