import { PostModel } from '../schemes/models/post.model';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PostProvider {

    constructor(private db: AngularFireDatabase) { }
    getPosts(): Observable<PostModel[]> {
      return this.db.list<PostModel>(`posts`).valueChanges();
    }

    getPostsByLocation(location: string): Observable<PostModel[]>{
      return this.db.list<PostModel>('posts', ref => ref.orderByChild('location').equalTo(location)).valueChanges();
    }
    
    createPost(post: PostModel): Promise<void> {
      return this.db.object<PostModel>(`posts/${post.id}`).set(post);
    }
    
    getUserPosts(userId: string): Observable<PostModel[]> {
      return this.db.list<PostModel>(`posts`, ref => ref.orderByChild('userId').equalTo(userId)).valueChanges();
    }

    deletePost(postId): Promise<void> {
      return this.db.object<PostModel>(`posts/${postId}`).remove();
    }

    updatePost(post: PostModel): Promise<void> {
        return this.db.object<PostModel>(`posts/${post.id}`).update(post);
    }
    getPostById(postId: string): Observable<PostModel> {
      return this.db.object<PostModel>(`posts/${postId}`).valueChanges();
    }

    getPostsByField( fieldName: string, value: string | number, operation: "equalTo" | "startAt" | "endAt"):Observable<PostModel[]>{
      return this.db.list<PostModel>('posts', ref => ref.orderByChild(fieldName)[operation](value)).valueChanges();
    }

    setPostPhotoUrls(postId: string, postPhotoUrls: string[]) {
      return this.db.database.ref(`posts/${postId}`).child('photoUrls').set(postPhotoUrls).then(_ => postId);
  }
  getPostsByUserId(id: string): Observable<PostModel[]>{
    return this.db.list<PostModel>('posts', ref => ref.orderByChild('userId').equalTo(id)).valueChanges();
  }
  updatePostField(postId: string, fieldObj: any): Promise<void>{
    return this.db.object<PostModel>(`posts/${postId}`).update(fieldObj);
  }

  getTheMostExpensivePost(){
    return this.db.list<PostModel>('posts', ref => ref.orderByChild('price').limitToLast(1)).valueChanges();
  }

  getPostByCategory(category: string){
    return this.db.list<PostModel>('posts', ref => ref.orderByChild('category').equalTo(category)).valueChanges();
  }

  findCustomIdPost(customId: string){
    return this.db.list<PostModel>('posts', ref => ref.orderByChild('customId').equalTo(customId)).valueChanges();
  }
}
