import { PostModel, Category } from './../models/post.model';
import { Categories } from '../models/categories.model';
export enum PostFilterOrders{
    UPWARDPRICE = "Precio ascendente",
    DOWNWARDPRICE = "Precio descendente",
    NAME = "Nombre",
    CATEGORY = "Categoria"

}
export function orderByFilterOrder(order: string | number | {id: string, nm: string}){
    switch(order){
        case "UPWARDPRICE":
            return (a: PostModel,b: PostModel)=>
            a.price - b.price;
        case "DOWNWARDPRICE":
            return (a: PostModel, b: PostModel)=>
            b.price - a.price;
        case "NAME":
            return (a: PostModel, b: PostModel)=>{
                if (a.title.toLowerCase() > b.title.toLowerCase())
                    return 1;
                if (b.title.toLowerCase() > a.title.toLowerCase())
                    return -1;
                return 0;
            }
        case "CATEGORY":
            const categories: Category[] = new Categories().categories;
            return (a: PostModel, b:PostModel)=>
            categories.find(category => category.name == a.category).weight
            - categories.find(category => category.name == b.category).weight
    }
}

// function getOrderFunction(order: PostFilterOrders){
//     return (a,b)=>
// }
