import { UserProvider } from './../providers/user.provider';
import { PostProvider } from './../providers/post.provider';
import { switchMap, take } from 'rxjs/operators';
import { ChatProvider } from './../providers/chat.provider';
import { AuthService } from './auth.service';
import { ChatModel } from './../schemes/models/chat.model';
import { BehaviorSubject, of, Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  userChats: BehaviorSubject<{img?: string, chat: ChatModel, title?: string, username?: string, price?: number, productOwner?: string,
  customId?: string, deletedPost?: boolean}[]> = new BehaviorSubject([]);
  chatSubscription: Subscription;
  chatsSubscriptions: {[key:string]:Subscription};

  constructor(private authService: AuthService, private chatProvider: ChatProvider,
     private postProvider: PostProvider, private userProvider: UserProvider,
     ) { }

  setUserChats() {
    this.userChats.next([]);
    this.chatsSubscriptions = {};
    // const a = this.authService.getCurrentUser();
    this.chatSubscription = this.authService.getCurrentUser().pipe(switchMap(res => {
      if (!res || !res.chatsIds) { return of(null); }
      return res.chatsIds.map(currentChat => {
        const subscription = this.chatProvider.getChatById(currentChat).subscribe(async obtainedChat => {

          const user = await this.userProvider.getUserById(obtainedChat.members[0].userId === this.authService.getCurrentUserUid()
          ? obtainedChat.members[1].userId : obtainedChat.members[0].userId).pipe(take(1)).toPromise();
          if (obtainedChat.deletedTitle) {
            if (this.userChats.getValue().find(savedChat => savedChat.chat.id == obtainedChat.id)) {
              this.userChats.getValue()[this.userChats.getValue().findIndex(chat => chat.chat.id == obtainedChat.id)] = {
                chat: obtainedChat,
                title: obtainedChat.deletedTitle,
                username: user.name,
                deletedPost: true
              };
            } else {
              this.userChats.getValue().push({
                chat: obtainedChat, title: obtainedChat.deletedTitle, username: user.name, deletedPost: true
              });
            }
            this.userChats.next(this.userChats.getValue());
          } else {
            let post = await this.postProvider.getPostById(obtainedChat.postId).pipe(take(1)).toPromise();
            let photo = post.photoUrls && post.photoUrls.length > 0 ? post.photoUrls[0] : null;
            if(this.userChats.getValue().find(savedChat => savedChat.chat.id == obtainedChat.id)){
              this.userChats.getValue()[this.userChats.getValue().findIndex(chat => chat.chat.id == obtainedChat.id)] = {
                img: photo ,
                chat: obtainedChat,
                title: post.title,
                username: user.name,
                price: post.price,
                productOwner: post.userId,
                customId: post.customId
              };
            } else {
              this.userChats.getValue().push({img:photo, chat:obtainedChat, title: post.title,
                  username: user.name, price: post.price, productOwner: post.userId, customId: post.customId});
            }
            this.userChats.next(this.userChats.getValue());
          }
        });
        this.chatsSubscriptions[currentChat] = subscription;
        return subscription;
      });
    })).subscribe();
  }
  unSubscribeChats() {
    Object.values(this.chatsSubscriptions).forEach(subscription => {
      subscription.unsubscribe();
    });
    this.chatSubscription.unsubscribe();
  }
  getUserChats() {
    return this.userChats;
  }
  getChatsNotifications() {
    let notifications = 0;
    this.userChats.getValue().forEach(chat => {
      if (!chat.chat.deletedTitle) { notifications += chat.chat.totalMessages - chat.chat.readMessages[this.authService.getCurrentUserUid()]; }
    });
    // console.log(notifications);

    return notifications;
  }
}
