import { MessageService } from '../../../../services/messages.service';
import { Router, UrlHandlingStrategy } from '@angular/router';
import { UserModel } from '../../../../schemes/models/user.model';
import { UserProvider } from '../../../../providers/user.provider';
import { PostProvider } from '../../../../providers/post.provider';
import { UtilsService } from '../../../../services/utils.service';
import { MessageTypes } from '../../../../schemes/enums/message-types.enum';
import { AuthService } from '../../../../services/auth.service';
import { ChatProvider } from '../../../../providers/chat.provider';
import { MessageProvider } from '../../../../providers/message.provider';
import { MessageModel } from '../../../../schemes/models/message.model';
import { ChatModel } from '../../../../schemes/models/chat.model';
import { Component, OnInit, ViewChild, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {

  chat: (ChatModel & { product?: string });
  messages: MessageModel[];
  // chatTitle: string = ';
  messageToSend: string = '';
  messagesSubscription: Subscription;
  product: string;
  productOwner: string;
  chatId: string;
  postId: any;
  title: any;
  pauseSubscription: Subscription;
  currentUserId: string;
  disableSend: boolean;
  productImg: string;
  strangerToken: string;
  @ViewChild('content', { static: false }) private content: any;
  firstTimeScroll: boolean = true;
  resumeSubscription: Subscription;
  user: UserModel;
  price: number;
  closed: boolean = false;
  chatSubscription: Subscription;
  customId: string;
  deleted: boolean = false;
  postSubscription: Subscription;
  fromPost: boolean = false;
  isLoading = true
  routerSubscription: Subscription;
  @Input() data;
  @Input() showSidebarMobile: boolean;
  @Output() showSidebarMobileEvent : EventEmitter<boolean> = new EventEmitter<boolean>();
  // errorMessagesBHSubscription: Subscription;
  // errorMessages: (MessageModel & { error?: boolean })[] = [];

  constructor(
    private messageProvider: MessageProvider,
    private chatProvider: ChatProvider,
    private authService: AuthService,
    private utilsService: UtilsService,
    private postProvider: PostProvider,
    private userProvider: UserProvider,
    private messageService: MessageService,
    private router: Router,
  ) { }

  async ngOnInit() {
    // this.routerSubscription = this.router.events.subscribe((val: any) => {
    //   console.log(val);

    // })
    // this.utilsService.dataObservable.subscribe(async data => {
    //   console.log("es este observable?");
    //   console.log(data);

      if (this.data.chat) {
        this.postSubscription = this.postProvider.getPostById(this.data.chat.postId).subscribe(res => {
          if (!res) { this.deleted = true; }
        });
      }
      console.log(this.data);
      this.currentUserId = this.authService.getCurrentUserUid();
      this.customId = this.data.customId || null;
      this.chat = this.data.chat || null;
      this.fromPost = this.data.fromPost || false;
      this.product = this.data.product || null;
      this.chatId = this.chat ? this.chat.id : this.chatProvider.getRandomPushKey();
      this.userProvider.updateUserField(this.authService.getCurrentUserUid(), { currentChatId: this.chatId });
      this.user = await this.userProvider.getUserById(this.authService.getCurrentUserUid()).pipe(take(1)).toPromise();
      this.productOwner = this.data.productOwner || null;
      this.title = this.data.title || this.chat.title;
      this.productImg = this.data.productImg || null;
      this.price = this.data.price || null;
      this.postId = this.data.postId || this.chat.postId;
      this.chatSubscription = this.chatProvider.getChatById(this.chatId).subscribe(res => {
        console.log({resChat: res});
        this.chat = res;
        if (res && res.closed) {
          this.closed = true;
        } else if (!res || (res && !res.closed)){
          this.closed = false;
        }
      });
      console.log("se va a ahcer una subscripcion");

      this.messagesSubscription = this.messageProvider.getMessagesByChatId(this.chatId).subscribe(res => {
        this.messages = res;
        console.log(this.chatId);

        if (this.firstTimeScroll) {
          // this.scrollToBottomOnInit();
          this.firstTimeScroll = false;
        }
        if (this.chat) {
          console.log("chat por aqui" + this.messages.length);
          console.log(this.chatId);

          this.chatProvider.updateChatReadMessages(this.chatId, { [this.authService.getCurrentUserUid()]: this.messages.length });
        }
        // this.chatProvider.updateChatReadMessages(this.chatId, { [this.authService.getCurrentUserUid()]: this.messages.length })
        // this.userProvider.updateUserField(this.authService.getCurrentUserUid(), { currentChatId: this.chatId });
        this.isLoading = false;
      });
    // });
  }

  ngOnDestroy(): void {
    console.log("destroy");
    this.messagesSubscription.unsubscribe();
    if(this.chat){
      // this.userProvider.updateUserField(this.authService.getCurrentUserUid(), { currentChatId: '' });
      this.chatProvider.updateChatReadMessages(this.chatId, { [this.authService.getCurrentUserUid()]: this.messages.length });
    }
    this.postSubscription && this.postSubscription.unsubscribe();
    // this.pauseSubscription && this.pauseSubscription.unsubscribe();
    // this.resumeSubscription && this.resumeSubscription.unsubscribe();
    this.chatSubscription && this.chatSubscription.unsubscribe();
  }

  async closeDialog() {
    this.messagesSubscription.unsubscribe();
    this.router.navigateByUrl('/client/chats');
    // await this.modalController.dismiss();
  }


  isCurrentUserMessage(message: MessageModel): boolean {
    return message.senderId === this.currentUserId;
  }
  showMessages() {
    return;
  }
  async sendMessageAction() {
    if(this.chat && this.chat.deletedTitle) {
      console.log('deletedtitle');
      this.closeDialog();
      return;
    }
    this.disableSend = true;
    const tempMessage = this.messageToSend;
    this.messageToSend = '';
    console.log(this.chat);

    if (!this.chat) {
      const chat: ChatModel = {
        id: this.chatId, createdAt: Date.now(), updatedAt: Date.now(), totalMessages: 0, lastMessage: '',
        readMessages: { [this.authService.getCurrentUserUid()]: 0, [this.productOwner]: 0 },
        members: [{userId: this.authService.getCurrentUserUid(), storedChat:false},{userId:this.productOwner, storedChat: false} ], title: this.title, postId: this.postId
      };
      this.chatProvider.createChat(chat).then(_ => {
        console.log("creando1 por la cara");

        this.chat = chat;
        this.disableSend = false;
        this.postProvider.getPostById(this.postId).pipe(take(1)).toPromise().then(res => {
          if (!res.chats) { res.chats = []; }
          res.chats.push(chat.id);
          this.postProvider.updatePostField(res.id, { chats: res.chats })
        })
        if(!this.user.chatsIds){
          this.user.chatsIds = [];
        }
        console.log(this.user.chatsIds);

        this.user.chatsIds.push(this.chatId);
        this.userProvider.updateUserField(this.authService.getCurrentUserUid(), {chatsIds: this.user.chatsIds});
        if (!this.chat) {
          // this.postProvider.getPostById(this.postId).pipe(take(1)).toPromise().then(res => {
          this.userProvider.getUserById(this.productOwner).pipe(take(1)).toPromise().then(user => {
            let strangerChatsIds = user.chatsIds;
            if(!strangerChatsIds)strangerChatsIds = [];
            strangerChatsIds.push(this.chatId)
            this.userProvider.updateUserField(user.id, {chatsIds: strangerChatsIds});
          })
        } else {
          const strangerId = this.chat.members.find(member => member.userId !== this.currentUserId);
          this.userProvider.getUserById(strangerId.userId).pipe(take(1)).toPromise().then(user => {
            let strangerChatsIds = user.chatsIds;
            if(!strangerChatsIds)strangerChatsIds = [];
            strangerChatsIds.push(this.chatId)
            this.userProvider.updateUserField(user.id, {chatsIds: strangerChatsIds});
          })
        }
        this.sendMessage(tempMessage, false);
      }).catch(e => {
        this.disableSend = false;
        this.utilsService.showPrimeToast('error', '', 'Ha ocurrido un error, vuelva a intentarlo');
      })
    } else {
      this.disableSend = false;
      this.sendMessage(tempMessage, false);
    }
  }

  goBack() {
    this.isLoading = true;
    if (!this.messages) {
      this.router.navigateByUrl('/client/chats');
    } else {
      this.router.navigateByUrl('/client/chats');
      this.closeDialog();
    }
  }
  async sendMessage(messageToSend: string, internetError: boolean) {
    const message: MessageModel = {
      text: messageToSend,
      id: this.messageProvider.getRandomPushKey(),
      chatId: this.chat.id,
      type: MessageTypes.text,
      senderId: this.authService.getCurrentUserUid(),
      createdAt: 0
    }
    if (internetError) {
      this.messageService.addErrorMessage(this.chatId, message);
      return;
    }
    const currentTime = await this.utilsService.getServerTimeStamp().catch(e => this.utilsService.showPrimeToast('error', '', 'Un error ha ocurrido, vuelva a intentarlo con una mejor conexión a internet'));
    if (!currentTime) return;
    message.createdAt = currentTime;
    this.messages.push(message);
    this.messageProvider.createMessage(message).then(async _ => {
      // this.scrollToBottomOnInit();
      const chatMembers: {userId: string, storedChat: boolean }[] = JSON.parse(JSON.stringify(this.chat.members));
      console.log("chatmembers");

      if(chatMembers.find(chatMember => chatMember.storedChat)){
        chatMembers.forEach(chatMember => {
          chatMember.storedChat = false;
        })
        await this.chatProvider.updateChat(this.chat.id, { members: chatMembers,  totalMessages: this.messages.length, lastMessage: messageToSend, updatedAt: currentTime });
      }else
        await this.chatProvider.updateChat(this.chat.id, {  totalMessages: this.messages.length, lastMessage: messageToSend, updatedAt: currentTime });
      let canSendNotification = false;
      if (!this.chat) {
        // this.postProvider.getPostById(this.postId).pipe(take(1)).toPromise().then(res => {
        this.userProvider.getUserById(this.productOwner).pipe(take(1)).toPromise().then(user => {
          this.strangerToken = user.token;
          canSendNotification = ((user.currentChatId != this.chatId && user.currentChatId != '' && user.currentChatTab) || !user.currentChatTab);
          this.messageProvider.sendMessage(messageToSend, this.strangerToken, this.title, this.chatId, canSendNotification);
        })
      } else {
        const strangerId = this.chat.members.find(member => member.userId !== this.currentUserId);
        this.userProvider.getUserById(strangerId.userId).pipe(take(1)).toPromise().then(user => {
          this.strangerToken = user.token;
          canSendNotification = ((user.currentChatId != this.chatId && user.currentChatId != '' && user.currentChatTab) || !user.currentChatTab);
          this.messageProvider.sendMessage(messageToSend, this.strangerToken, this.title, this.chatId, canSendNotification);
        })
      }
    });
  }

  scrollToBottomOnInit() {
    // this.content.scrollToBottom(300);
  }

  onGoToPost(): void {
    this.utilsService.setDataForNavigation({postId: this.postId, fromChat: true});
    this.router.navigateByUrl(`/post`);
  }

  isOwner(){
    return this.user.id == this.productOwner;
  }
  async closeChat(){
    // console.log('closeando');

    // const closeModal = await this.modalController.create({
    //   component: CloseChatModalComponent,
    //   cssClass: 'close-chat-modal',
    //   backdropDismiss: false,
    //   showBackdrop: true,
    //   componentProps: {postId: this.postId, chatId: this.chatId}
    // });

    // await closeModal.present();
  }
  async onInfoButton(){
    // const infofAlert = new Promise(async (resolve) => {
    //   const alert = await this.alertController.create({
    //     message:
    //       `Este es un identificador generado por Chispa para identificar tu producto.
    //       Lo puedes usar para organizar tus productos en el almacén junto con este número. De esa forma un cliente podrá
    //       preguntarte por un producto usando este identificador o a la Inversa y así tú tendrás claro a que producto hace referencia.`,
    //       buttons: [
    //       {
    //         text: 'Aceptar',
    //         cssClass: 'info-button',
    //         handler: () => resolve(true)

    //       }
    //     ]
    //   });
    //   alert.present();
    // });
  }
  isToday(chatTimestamp: number): boolean{
    const chatDate = new Date(chatTimestamp);
    const nowDate = new Date();
    return (chatDate.getDate() == nowDate.getDate() && chatDate.getMonth() == nowDate.getMonth() &&
    chatDate.getFullYear() == nowDate.getFullYear())
  }

  async storeChat() {
    let actualUser =  this.chat.members.find(member => member.userId == this.authService.getCurrentUserUid());
    actualUser.storedChat = !actualUser.storedChat;
    await this.chatProvider.updateChat(this.chat.id, { members: this.chat.members });
  }
  chatIsStored(){
    return this.chat && this.chat.members.find(member => member.userId == this.authService.getCurrentUserUid()).storedChat;
  }

  onSidebarMobile() {
    this.showSidebarMobile = !this.showSidebarMobile;
    console.log({chat: this.showSidebarMobile})
    this.showSidebarMobileEvent.emit(this.showSidebarMobile);
  }

}
