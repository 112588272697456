import { MessageModel } from './../schemes/models/message.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class MessageService {

    private errorMessages: { [key: string]: BehaviorSubject<((MessageModel & { error?: boolean })[])> } = {};

    constructor() { }

    addErrorMessage(chatId: string, errorMessage: MessageModel): void {
        if(!this.errorMessages[chatId]){
            this.errorMessages[chatId] = new BehaviorSubject([]);
            this.errorMessages[chatId].next([errorMessage]);
        }else{
            // !this.errorMessages[chatId] && (this.errorMessages[chatId].next([]));
            this.errorMessages[chatId].next([...this.errorMessages[chatId].getValue(),JSON.parse(JSON.stringify(errorMessage))]);
        }
    }

    removeErrorMessage(chatId: string, errorMessageId: string): void {
        if (!this.errorMessages[chatId]) return;
        // const removeIndex = this.errorMessages[chatId].getValue().findIndex(errorMessage => errorMessage.id === errorMessageId);
        // (removeIndex >= 0) && this.errorMessages[chatId].getValue().splice(removeIndex, 1);
        this.errorMessages[chatId].next(this.errorMessages[chatId].getValue().filter(message => message.id != errorMessageId));
    }

    getErrorMessages(chatId: string): (MessageModel & { error?: boolean })[] {
        if(!this.errorMessages[chatId])return [];
        else return this.errorMessages[chatId].getValue();
    }

    getErrorMessagesBH(chatId: string):BehaviorSubject<((MessageModel & { error?: boolean })[])>{
        return this.errorMessages[chatId];
    }
}