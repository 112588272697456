import { ChatModel } from 'src/app/schemes/models/chat.model';
// import { ChatModalComponentComponent } from './../components/chat-modal-component/chat-modal-component.component';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ChatProvider {


    constructor(
        private db: AngularFireDatabase,
    ) {}
    getRandomPushKey(): string {
        return this.db.createPushId();
    }
    getChatById(id: string): Observable<ChatModel>{
        return this.db.object<ChatModel>(`chats/${id}`).valueChanges();
    }
    createChat(chat: ChatModel){
      console.log("se llama 2 veces?");

        return this.db.object<ChatModel>(`chats/${chat.id}`).set(chat);
    }
    updateChat(chatId: string,updateValue: {[key: string]:any}){
        return this.db.object(`chats/${chatId}`).update(updateValue);
    }
    updateChatReadMessages(chatId: string,updateValue: {[key: string]:any}){
        return this.db.object(`chats/${chatId}/readMessages`).update(updateValue);
    }
    getAllChats(): Observable<ChatModel[]>{
        return this.db.list<ChatModel>('chats').valueChanges();
    }
}
