import { ChatService } from './../../services/chat.service';
import { TabsService } from './../../services/tabs.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/schemes/models/user.model';
import { trigger, transition, style, animate } from '@angular/animations';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger(
        'inOutAnimation',
        [
            transition(
                ':enter',
                [
                    style({ right: '-50%' }),
                    animate('0.3s ease-out', style({ right: 0 }))
                ]
            ),
            transition(
                ':leave',
                [
                    style({ right: 0 }),
                    animate('0.3s ease-in', style({ right: '-50%' }))
                ]
            )
        ]
    )
  ]
})

export class AdminComponent implements OnInit, OnDestroy {
  user: UserModel;
  active = '';
  selected = '';
  readyToShow = false;
  showSidenav = false;


  constructor(
      private authService: AuthService,
      private router: Router,
      private tabsService: TabsService,
      private chatService: ChatService,
  ) { }

  async ngOnInit() {
    this.authService.getCurrentUser().subscribe(user => {
        this.user = JSON.parse(JSON.stringify(user));
        this.readyToShow = true;
    });
    this.tabsService.getTabObservable().subscribe(tab => {
      this.active = tab;
    });
    this.chatService.setUserChats();
  }

  ngOnDestroy() {
  }

  inModal() {
      return '/client' !== this.router.url;
  }

  closeModal() {
      this.router.navigateByUrl('/client');
  }

  logOut(): void {
    this.authService.logout();
    this.router.navigate(['/products']);
  }
}
