import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  $search: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() { }

  getSearchBH(){
    return this.$search;
  }

  setSearchBHValue(value: string){
    this.$search.next(value);
  }
}
