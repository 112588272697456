import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dashboard';
  loading = false;

  constructor(private router: Router, private spinner: NgxSpinnerService) {
      this.router.events.subscribe((event: Event) => {
          console.log('Event Occured');
          switch (true) {
              case event instanceof NavigationStart: {
                  this.loading = true;
                  console.log(this.loading);
                  break;
              }
              case event instanceof NavigationEnd:
              case event instanceof NavigationError:
              case event instanceof NavigationCancel: {
                  this.loading = false;
                  console.log(this.loading);
                  break;
              }
              default: {
                  break;
              }
          }
      });
  }
}
